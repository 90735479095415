import { axiosInstance } from "../config";

let handlerEnabled = true;

const requestSubscriptionPlans = async () =>
  await axiosInstance.get(`/hrcomsite/subscriptions/plans`, {
    handlerEnabled,
  });

export default { requestSubscriptionPlans };
