import * as types from "../../types/website/pricing";

export default (state = {}, action) => {
  switch (action.type) {
    case types.SUBSCRIPTION_PLANS_RECEIVE:
      return { ...state, subscriptionPlans: [...action.payload] };
    default:
      return state;
  }
};
