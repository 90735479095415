import * as types from "../../types/dashboard/subscriptions";

export const dashboardSubscriptionPlansRequest = (data) => {
  return {
    type: types.DASHBOARD_SUBSCRIPTION_PLANS_REQUEST,
    payload: data,
  };
};

export const dashboardSubscriptionPlansRecieve = (data) => {
  return {
    type: types.DASHBOARD_SUBSCRIPTION_PLANS_RECIEVE,
    payload: data,
  };
};
export const getDashboardSubscriptionPlanRecieve = (data) => {
  return {
    type: types.GET_DASHBOARD_SUBSCRIPTION_PLAN_RECIEVE,
    payload: data,
  };
};
export const getDashboardSubscriptionPlanRequest = (data) => {
  return {
    type: types.GET_DASHBOARD_SUBSCRIPTION_PLAN_REQUEST,
    payload: data,
  };
};
export const getSubscriptionPaymentTypeRecieve = (data) => {
  return {
    type: types.GET_SUBSCRIPTION_PAYMENT_TYPE_RECIEVE,
    payload: data,
  };
};
export const getSubscriptionPaymentTypeRequest = (data) => {
  return {
    type: types.GET_SUBSCRIPTION_PATMENT_TYPE_REQUEST,
    payload: data,
  };
};
export const getSubscriptionCompaniesRecieve = (data) => {
  return {
    type: types.GET_SUBSCRIPTION_COMPANIES_RECIEVE,
    payload: data,
  };
};

export const getSubscriptionCompaniesRequest = (data) => {
  return {
    type: types.GET_SUBSCRIPTION_COMPANIES_REQUEST,
    payload: data,
  };
};
export const setSubscriptionPlanRequest = (data) => {
  return {
    type: types.SET_SUBSCRIPTION_PLAN_REQUEST,
    payload: data,
  };
};
export const deleteSubscriptionPlanRequest = (data) => {
  return {
    type: types.DELETE_SUBSCRIPTION_PLAN_REQUEST,
    payload: data,
  };
};
export const getDashboardSubscriptionUsedPlansRequest = (data) => {
  return {
    type: types.GET_DASHBOARD_SUBSCRIPTION_USED_PLAN_REQUEST,
    payload: data,
  };
};
