import * as types from "../../types/dashboard/promoCodes";

export const addPromoCodeRequest = (payload) => ({
  type: types.ADD_PROMO_CODE_REQUEST,
  payload,
});

export const addPromoCodeReceive = (payload) => ({
  type: types.ADD_PROMO_CODE_RECEIVE,
  payload,
});

export const getPromocodesListRequest = (payload) => {
  return {
    type: types.GET_PROMO_CODES_LIST_REQUEST,
    payload,
  };
};

export const getPromocodesListReceive = (payload) => ({
  type: types.GET_PROMO_CODES_LIST_RECEIVE,
  payload,
});

export const getSinglePromoCodeRequest = (payload) => ({
  type: types.GET_SINGLE_PROMO_CODE_REQUEST,
  payload,
});

export const getSinglePromoCodeReceive = (payload) => ({
  type: types.GET_SINGLE_PROMO_CODE_RECEIVE,
  payload,
});

export const editSinglePromoCodeRequest = (payload) => ({
  type: types.EDIT_SINGLE_PROMO_CODE_REQUEST,
  payload,
});

export const editSinglePromoCodeReceive = (payload) => ({
  type: types.EDIT_SINGLE_PROMO_CODE_RECEIVE,
  payload,
});

export const deletePromoCodeRequest = (payload) => ({
  type: types.DELETE_PROMO_CODE_REQUEST,
  payload,
});

export const deletePromoCodeReceive = (payload) => ({
  type: types.DELETE_PROMO_CODE_RECEIVE,
  payload,
});

export const sendPromoCodeRequest = (payload) => ({
  type: types.SEND_PROMO_CODE_REQUEST,
  payload,
});

export const sendPromoCodeReceive = (payload) => ({
  type: types.SEND_PROMO_CODE_RECEIVE,
  payload,
});

export const infoPromoCodeRequest = (payload) => ({
  type: types.INFO_PROMO_CODE_REQUEST,
  payload,
});

export const infoPromoCodeReceive = (payload) => ({
  type: types.INFO_PROMO_CODE_RECEIVE,
  payload,
});

export const infoPromoCodeSendByRequest = (payload) => ({
  type: types.INFO_PROMO_CODE_SEND_BY_REQUEST,
  payload,
});

export const infoPromoCodeSendByReceive = (payload) => ({
  type: types.INFO_PROMO_CODE_SEND_BY_RECEIVE,
  payload,
});
