import * as types from "../../types";

const INITIAL_STATE = {}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.REGISTRATION_RECEIVE:
            return { ...state, ...action.payload };
        case types.SEND_AGAIN_EMAIL_RECEIVE:
            return { ...state, ...action.payload };
        case types.CONFIRM_EMAIL_RECEIVE : 
            return {...state, ...action.payload};
        default: return state;
    }
}