import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/dashboard/subscriptions";
import * as Actions from "../../actions/dashboard/subscriptions";
import * as type from "../../types/dashboard/subscriptions";
import { dispatchError, showSnackbar } from "../../../utils/Shared";
import History from "routes/History";
import store from "store";
import messages from "assets/Local/messages";
import { showSnackbarAction } from "store/actions/snackbar";

function* requestDashboardSubscriptionPlans({ payload }) {
  try {
    const response = yield call(API.subscriptionPlanRequest, payload);
    yield put(Actions.dashboardSubscriptionPlansRecieve(response.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* getDashboardSubscriptionPlan({ payload }) {
  try {
    const response = yield call(API.getSubscriptionPlanRequest, payload);
    yield put(Actions.getDashboardSubscriptionPlanRecieve(response.data));
  } catch (error) {
    console.log(error);
    dispatchError(error.response.data);
  }
}
function* getSubscriptionCompanies({ payload }) {
  try {
    const response = yield call(API.getSubscriptionCompaniesRequest, payload);
    yield put(Actions.getSubscriptionCompaniesRecieve(response.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
function* getSubscriptionPaymentType({ payload }) {
  try {
    const response = yield call(
      API.getSubscriptionPaymentTypesRequest,
      payload
    );
    yield put(Actions.getSubscriptionPaymentTypeRecieve(response.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
function* setSubscriptionPlanRequest({ payload }) {
  try {
    const requestStatus = History.location.pathname.includes("edit")
      ? API.updateSubscriptionPlanRequest
      : API.setSubscriptionPlanRequest;
    const response = yield call(requestStatus, payload);
    if (response) showSnackbar("done");
    History.push("/admin/dashboard/planCustomization");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* deleteSubscriptionPlanRequest({ payload }) {
  try {
    const response = yield call(API.deleteSubscriptionPlanRequest, payload);
    if (response) showSnackbar("done");
    const result = yield call(API.subscriptionPlanRequest, payload);
    yield put(Actions.dashboardSubscriptionPlansRecieve(result.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* getDashboardSubscriptionUsedPlansRequest({ payload }) {
  try {
    const response = yield call(API.getSubscriptionUsedPlanRequest, payload);
    const lang = store.getState().locale.lang;
    let companiesUsedPlan = yield response.data.data.map((item) =>
      lang === "ar" ? item.name_ar : item.name_en
    );
    const message = messages[lang].snackbar["usedPlan"];
    store.dispatch(
      showSnackbarAction(
        `${message} ${
          companiesUsedPlan.length >= 1 ? `(${companiesUsedPlan.join()})` : ""
        }`,
        "error"
      )
    );
  } catch (error) {
    console.log(error);
    dispatchError(error.response?.data);
  }
}

export default function* DashboardSubscriptionsSagasWatch() {
  yield takeLatest(
    type.DASHBOARD_SUBSCRIPTION_PLANS_REQUEST,
    requestDashboardSubscriptionPlans
  );
  yield takeLatest(
    type.GET_DASHBOARD_SUBSCRIPTION_PLAN_REQUEST,
    getDashboardSubscriptionPlan
  );
  yield takeLatest(
    type.GET_SUBSCRIPTION_PATMENT_TYPE_REQUEST,
    getSubscriptionPaymentType
  );
  yield takeLatest(
    type.GET_SUBSCRIPTION_COMPANIES_REQUEST,
    getSubscriptionCompanies
  );
  yield takeLatest(
    type.SET_SUBSCRIPTION_PLAN_REQUEST,
    setSubscriptionPlanRequest
  );
  yield takeLatest(
    type.DELETE_SUBSCRIPTION_PLAN_REQUEST,
    deleteSubscriptionPlanRequest
  );
  yield takeLatest(
    type.GET_DASHBOARD_SUBSCRIPTION_USED_PLAN_REQUEST,
    getDashboardSubscriptionUsedPlansRequest
  );
}
