import { call, takeLatest } from 'redux-saga/effects';
import API from '../../../network/apis/dashboard/announcement';
import * as type from '../../types/index';
import { showSnackbar, dispatchError } from '../../../utils/Shared';
import History from '../../../routes/History';

function* addAnnouncement({ payload }) {
  try {
    yield call(API.addAnnouncement, payload);
    showSnackbar('done');
    History.goBack();
  } catch (error) {
    dispatchError(error.response.data);
  }
}

export default function* announcementSagasWatch() {
  yield takeLatest(type.ADD_ANNOUNCEMENT_REQUEST, addAnnouncement);
}
