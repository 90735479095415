import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { setCurrentLang } from "../../../store/actions/Lang";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import DropDownMenu from "../DropdownMenu/DropdownMenu";
import DrawerMenuComponent from "../DrawerMenu/DrawerMenu";
import history from '../../../routes/History';
import "./Navbar.scss";

function Navbars(props) {
  const lang = useSelector((state) => state.locale.lang);
  const sideMenu = useSelector((state) => state.dashboard.sideMenu);
  const [titlePage , setTitlePage] = useState('');
  const { messages } = props.intl;
  const dispatch = useDispatch();
  const switcher = (lang) => {
    dispatch(setCurrentLang(lang === "ar" ? "en" : "ar"));
  };

  useEffect(() => {
    setTitlePage((history.location.pathname).split('/')[3])
  })

  return (
    <React.Fragment>
      <Navbar
        className="customNavbar"
        collapseOnSelect
        expand="lg"
        style={{
          marginLeft: sideMenu.navbarMarginLeft,
          marginRight: sideMenu.navbarMarginRight,
        }}
      >
        <DrawerMenuComponent />
          <Navbar.Brand className="bold_font mb-0 mx-5">
            {messages.sideMenu[titlePage]}
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <div className="align-self-center text-white">
              <DropDownMenu />
            </div>
            <Nav.Link className="align-self-center">
              <button
                className="btn btn-link langBtn text-dark bold_font align-self-center"
                id="en"
                onClick={() => switcher(lang)}
              >
                {messages.langBtn}
              </button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  );
}

const HeaderComponent = injectIntl(Navbars);

export default HeaderComponent;
