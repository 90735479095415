import { call, takeLatest, put } from "redux-saga/effects";
import API from "../../../network/apis/auth";
import * as types from "../../types/website/auth";
import { dispatchError, showSnackbar ,showPopUp} from "../../../utils/Shared";
import History from "routes/History";
import { colorsWithPlan } from "containers/Login/CompanyPortalSetup";
import {
  companyPortalSetupRecieve,
} from "store/actions/website/auth";
import cssVariables from "../../../containers/Login/_CompanyPortalVariables.scss";
import { saveTwoFactorAuthData } from "website/containers/TwoFactorAuthentication/TwoFactorAuthenticationUtils";
import store from "store";

export function* CompanyPortalSetupRecieve() {
  yield call(API.getCompanyPortalSetupRequest);
}
export function* getCompanyPortalSetup() {
  try {
    const response = yield call(API.getCompanyPortalSetupRequest);
    const brandedSite = colorsWithPlan(response.data.data);
    yield put(companyPortalSetupRecieve(brandedSite));

    document.documentElement.style.setProperty(
      "--company-primary-color",
      brandedSite.primary_color
    );
    document.documentElement.style.setProperty(
      "--company-secondary-color",
      brandedSite.secondary_color
    );
    localStorage.setItem("company-primary-color", brandedSite.primary_color);
    localStorage.setItem("company-secondary-color", brandedSite.secondary_color);
    cssVariables.secondaryColor = brandedSite.secondary_color;
    yield call(CompanyPortalSetupRecieve);
  } catch (err) {
    err?.response?.data?.error && dispatchError(err?.response?.data);
  }
}
export function* redirectCompanyToLogin({ payload }) {
  try {
    const response = yield call(API.companyLogin, payload);
    const data = response.data.data;
    /* Save Data For 2fa Steps*/
    saveTwoFactorAuthData({
      email: payload.email,
      domain: payload.domain,
      ...data
    });
    !data.is_two_factor_enabled
      ? History.push("/2fa")
      : History.push("/2fa-enabled");
  } catch (error) {
    error?.response?.data && dispatchError(error?.response?.data);
  }
}
export function* redirectCompanyLoginWithOTP({ payload }) {
  try {
    const response = yield call(
      API.requestRedirectCompanyLoginWithOTP,
      payload
    );
    localStorage.removeItem("company-primary-color");
    localStorage.removeItem("company-secondary-color");
    window.location.href = response.data?.data?.redirect_url;
  } catch (error) {
    dispatchError(error?.response?.data);
  }
}
export function* redirectCompanyToValidateDomain(Actions) {
  try {
    const response = yield call(API.checkDomain, Actions.payload);
    yield put(Actions.receiveValidateDomain(response.data.data));
  } catch (err) {
    if (err.request.status === 404) {
      History.push("/notFound");
    } else if (err.request.status === 409) {
      yield put(Actions.receiveValidateDomain(err.response.data.error.message));
    }
    dispatchError(err.response.data);
  }
}

function* companyLogin({ payload }) {
  try {
    const response = yield call(API.companyLogin, payload);
    window.location.href = response.data.data.redirect_url;
  } catch (error) {
    dispatchError(error?.response?.data);
  }
}

function* companyForgetPassword({ payload }) {
  try {
    yield call(API.companyForgetPassword, payload);
    History.push("/companyLogin");
    showSnackbar("done");
  } catch (error) {
    dispatchError(error?.response?.data);
  }
}


export function* sendTwoFactorCode(action) {
  try {
    const response = yield call(API.sendTwoFactorCode, action.payload);
    yield put(action.receiveSendTwoFactorCode(response.data.data));
    console.log(store.getState().locale.lang )
    showPopUp(store.getState().locale.lang === "ar"?"تحقق من البريد الالكتروني لباركود اعادة تعيين التوثيق الثنائي": "Check email to reset 2FA QR code");
   
  } catch (err) {
    console.log(store.getState().locale.lang )
    showPopUp(store.getState().locale.lang === "ar"?"تحقق من البريد الالكتروني لباركود اعادة تعيين التوثيق الثنائي": "Check email to reset 2FA QR code");
    dispatchError(err.response?.data);
  }
}
export function* signinWithSocialSDKRequest({ payload }) {
  try {
    const response = yield call(API.socialLoginsRequest, payload);
    window.location.href = response.data.data.redirect_url;
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("domain");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* authSagasWatch() {
  yield takeLatest(types.COMPANY_LOGIN_REQUEST, companyLogin);
  yield takeLatest(
    types.COMPANY_FORGET_PASSWORD_REQUEST,
    companyForgetPassword
  );

  yield takeLatest(types.SOCIAL_LOGIN_REQUEST, signinWithSocialSDKRequest);

  yield takeLatest(
    types.REDIRECT_COMPANY_TO_LOGIN_REQUEST,
    redirectCompanyToLogin
  );
  yield takeLatest(
    types.REDIRECT_COMPANY_TO_VALIDATE_DOMAIN_REQUEST,
    redirectCompanyToValidateDomain
  );
  yield takeLatest(
    types.GET_COMPANY_PORTAL_SETUP_REQUEST,
    getCompanyPortalSetup
  );
  yield takeLatest(
    types.REQUEST_REDIRECT_COMPANY_LOGIN_WITH_OTP,
    redirectCompanyLoginWithOTP
  );
  yield takeLatest(types.SEND_TOW_FACTOR_CODE_REQUEST, sendTwoFactorCode);

}