import * as types from "../../types";
import * as socialTypes from "store/types/website/auth";
import { getURLParams } from "utils/Shared";

const INITIAL_STATE = {
  isModalOpen: false,
  socialLoginPreContentFilled: false,
  Open: false,
  socialLoginPreContent: {},
  provider: getURLParams('provider')
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.IS_MODAL_OPEN:
      return { ...state, isModalOpen: action.payload };
    case socialTypes.SOCIAL_LOGIN_PREREQUESTION_MODAL_REQUEST:
      return {
        ...state,
        Open: action.payload.open,
        provider: action.payload.provider,
      };
    case socialTypes.SOCIAL_LOGIN_PREREQUESTION_FILLED_REQUEST:
      return {
        ...state,
        socialLoginPreContentFilled: state.provider,
        socialLoginPreContent: action.payload,
        Open: false,
      };
    case socialTypes.SOCIAL_LOGIN_POPUP_OPENED_REQUEST:
      return {
        ...state,
        socialLoginPreContentFilled: null,
        Open: false,
      };
    case socialTypes.SOCIAL_LOGIN_PHONE_NUMBER_REQUIRED:
      return {
        ...state,
        phoneNumberRequired: true,
        socialLoginPreContent: action.payload,
        Open: true,
      };
    default:
      return state;
  }
};
