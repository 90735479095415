import cssVariables from "./_CompanyPortalVariables.scss";

export const defaultSetupColors_Logos = {
  colors: {
    primary_color: cssVariables.defaultPrimaryColor,
    secondary_color: cssVariables.defaultSecondaryColor,
  },
};

export const expireAfter3Days = (date) => {
  const endDate = new Date(date);
  endDate.setDate(endDate.getDate() + 3);
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  return today > endDate;
};

export const colorsWithPlan = ({
  primary_color,
  secondary_color,
  plan_features: { has_brand_colors },
  company_subscription_details: { current_plan_end_date },
}) => {
  if (!has_brand_colors || expireAfter3Days(current_plan_end_date)) {
    return {
      ...defaultSetupColors_Logos.colors,
    };
  } else if (has_brand_colors && !expireAfter3Days(current_plan_end_date)) {
    return {
      transparent_color:
        primary_color === "#86364e" && secondary_color === "#ea8685"
          ? `rgba(172, 69, 86, 0.06)`
          : `rgba(0, 0, 0, 0.03)`,
      primary_color: primary_color
        ? primary_color
        : defaultSetupColors_Logos.primary_color,
      secondary_color: secondary_color
        ? secondary_color
        : defaultSetupColors_Logos.secondary_color,
    };
  }
};
