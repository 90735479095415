import * as types from "../../types/dashboard/dashboard";

export const requestDashboardData = () => ({
  type: types.DASHBOARD_DATA_REQUEST,
});
export const receiveDashboardData = (payload) => ({
  type: types.DASHBOARD_DATA_RECEIVE,
  payload,
});

export const requestDashboarPlansdData = () => ({
  type: types.DASHBOARD_PLANS_REQUEST,
});
export const receiveDashboarPlansdData = (payload) => ({
  type: types.DASHBOARD_PLANS_RECEIVE,
  payload,
});

export const requestDashboarPackagesData = () => ({
  type: types.DASHBOARD_PACKAGES_REQUEST,
});
export const receiveDashboardPackagesData = () => ({
  type: types.DASHBOARD_PACKAGES_RECIEVE,
});
