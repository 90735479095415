import * as types from "../../types/website/auth";

export const companyLogin = (payload) => ({
  type: types.COMPANY_LOGIN_REQUEST,
  payload,
});

export const requestCompanyForgetPassword = (data) => {
  return {
    type: types.COMPANY_FORGET_PASSWORD_REQUEST,
    payload: data,
  };
};
export const socialLoginRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_REQUEST,
  payload,
});

export const socialLoginModalRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_PREREQUESTION_MODAL_REQUEST,
  payload,
});
export const socialLoginModalFillRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_PREREQUESTION_FILLED_REQUEST,
  payload,
});
export const socialLoginPopupOpenedRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_POPUP_OPENED_REQUEST,
  payload,
});
export const socialLoginPhoneRequired = (payload) => ({
  type: types.SOCIAL_LOGIN_PHONE_NUMBER_REQUIRED,
  payload,
});
export const redicrectCompanyToLogin = (data) => {
  return {
    type: types.REDIRECT_COMPANY_TO_LOGIN_REQUEST,
    payload: data,
  };
};
export const redicrectCompanyToValidateDomain = (data) => {
  return {
    type: types.REDIRECT_COMPANY_TO_VALIDATE_DOMAIN_REQUEST,
    payload: data,
  };
};
export const companyPortalSetupRequest = () => {
  return {
    type: types.GET_COMPANY_PORTAL_SETUP_REQUEST
  }
};
export const companyPortalSetupRecieve = (payload) => {
  return {
    type: types.GET_COMPANY_PORTAL_SETUP_RECIEVE,
    payload
  }
};
export const requestRedirectCompanyLoginWithOTP = (payload) => {
  return {
    type: types.REQUEST_REDIRECT_COMPANY_LOGIN_WITH_OTP,
    payload
  }
};


export const requestSendTwoFactorCode = (data) => {
  return {
    type: types.SEND_TOW_FACTOR_CODE_REQUEST,
    payload: data,
  };
};

export const receiveSendTwoFactorCode = (data) => {
  return {
    type: types.SEND_TOW_FACTOR_CODE_RECEIVE,
    payload: data,
  };
};