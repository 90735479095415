import React from 'react' ;
import { Route, Redirect } from 'react-router-dom';
import MainLayoutComponent from '../../containers/MainLayout/MainLayout';

export const MainLayout = (component) => {
  return <MainLayoutComponent>{component}</MainLayoutComponent>
}

export const NoLayout = (component) => {
  return <>{component}</>
}

export const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const isAuthenticated = localStorage.getItem('token');
    return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated
          ? path.split("/")[3] === "resetPassword" ||
            path.split("/")[3] === "forgetPassword" ||
            path.split("/")[3] === "login"
            ? MainLayout(<Redirect to="/admin/dashboard/registeredCompanies" />)
            : MainLayout(<Component {...props} />)
          : path.split("/")[3] === "resetPassword" ||
            path.split("/")[3] === "forgetPassword" || 
            path.split("/")[3] === "login"
          ? NoLayout(<Component {...props} />)
          : NoLayout(<Redirect to={{ pathname: "/admin/dashboard/login" }} />)
      }
    />
  );
};