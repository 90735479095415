export const ADD_PROMO_CODE_REQUEST = "ADD_PROMO_CODE_REQUEST";
export const ADD_PROMO_CODE_RECEIVE = "ADD_PROMO_CODE_RECEIVE";
export const GET_PROMO_CODES_LIST_REQUEST = "GET_PROMO_CODES_LIST_REQUEST";
export const GET_PROMO_CODES_LIST_RECEIVE = "GET_PROMO_CODES_LIST_RECEIVE";
export const GET_SINGLE_PROMO_CODE_REQUEST = "GET_SINGLE_PROMO_CODE_REQUEST";
export const GET_SINGLE_PROMO_CODE_RECEIVE = "GET_SINGLE_PROMO_CODE_RECEIVE";
export const EDIT_SINGLE_PROMO_CODE_REQUEST = "EDIT_SINGLE_PROMO_CODE_REQUEST";
export const EDIT_SINGLE_PROMO_CODE_RECEIVE = "EDIT_SINGLE_PROMO_CODE_RECEIVE";
export const DELETE_PROMO_CODE_REQUEST = "DELETE_PROMO_CODE_REQUEST";
export const DELETE_PROMO_CODE_RECEIVE = "DELETE_PROMO_CODE_RECEIVE";
export const SEND_PROMO_CODE_REQUEST = "SEND_PROMO_CODE_REQUEST";
export const SEND_PROMO_CODE_RECEIVE = "SEND_PROMO_CODE_RECEIVE";
export const INFO_PROMO_CODE_REQUEST = "INFO_PROMO_CODE_REQUEST";
export const INFO_PROMO_CODE_RECEIVE = "INFO_PROMO_CODE_RECEIVE";

export const INFO_PROMO_CODE_SEND_BY_REQUEST =
  "INFO_PROMO_CODE_SEND_BY_REQUEST";
export const INFO_PROMO_CODE_SEND_BY_RECEIVE =
  "INFO_PROMO_CODE_SEND_BY_RECEIVE";
