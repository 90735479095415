import * as types from "../../types";

const INITIAL_STATE = {
  profile_setup: {},
  is_registered_before: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RECEIVE_GET_CANDIDATE_SETUP:
      return { ...state, profile_setup: { ...action.payload } };
    case types.RECEIVE_IS_CANDIDATE_REGISTRED_BEFORE:
      return { ...state, is_registered_before: action.payload };
    default:
      return state;
  }
};
