import { axiosInstance } from "../config";

let handlerEnabled = true;

const subscriptionPlanRequest = async ({ pageNumber }) => {
  let params = {
    page_number: pageNumber ? pageNumber : 1,
    page_size: 10,
  };
  return await axiosInstance.get("/hrcomsite/subscription_plans", {
    params,
    handlerEnabled,
  });
};
const getSubscriptionPlanRequest = async (plan_id) => {
  return await axiosInstance.get(`/hrcomsite/subscription_plans/${plan_id}`, {
    handlerEnabled,
  });
};
const getSubscriptionUsedPlanRequest = async (plan_id) => {
  return await axiosInstance.get(`/hrcomsite/subscription_plans/${plan_id}/registered_companies`, {
    handlerEnabled,
  });
};
const setSubscriptionPlanRequest = async (data) => {
  return await axiosInstance.post(`/hrcomsite/subscription_plans`,data, {
    handlerEnabled,
  });
};
const updateSubscriptionPlanRequest = async (data) => {
  return await axiosInstance.put(`/hrcomsite/subscription_plans/${data.id}`,data, {
    handlerEnabled,
  });
};
const deleteSubscriptionPlanRequest = async (id) => {
  return await axiosInstance.delete(`/hrcomsite/subscription_plans/${id}`, {
    handlerEnabled,
  });
};
const getSubscriptionCompaniesRequest = async () => {
  return await axiosInstance.get(`/hrcomsite/subscription_plans/companies`, {
    handlerEnabled,
  });
};
const getSubscriptionPaymentTypesRequest = async () => {
  return await axiosInstance.get(`/hrcomsite/subscription_plans/payment_types`, {
    handlerEnabled,
  });
};
export default {
  subscriptionPlanRequest,
  getSubscriptionPlanRequest,
  getSubscriptionCompaniesRequest,
  getSubscriptionPaymentTypesRequest,
  setSubscriptionPlanRequest,
  updateSubscriptionPlanRequest,
  deleteSubscriptionPlanRequest,
  getSubscriptionUsedPlanRequest
};
