import * as types from "../../types/dashboard/registeredCompanies";

//REGISTERED COMPANIES
export const registeredCompaniesRequest = (payload) => ({
  type: types.REGISTERED_COMPANIES_REQUEST,
  payload,
});
export const registeredCompaniesReceive = (payload) => ({
  type: types.REGISTERED_COMPANIES_RECEIVE,
  payload,
});

//ACTIVATE COMPANIES
export const activateCompaniesRequest = (payload) => ({
  type: types.ACTIVATE_COMPANIES_REQUEST,
  payload,
});
export const deactivateCompaniesRequest = (payload) => ({
  type: types.DEACTIVATE_COMPANIES_REQUEST,
  payload,
});

//TABS COUNT
export const tabsCountRequest = (payload) => ({
  type: types.TABS_COUNT_REQUEST,
  payload,
});
export const tabsCountReceive = (payload) => ({
  type: types.TABS_COUNT_RECEIVE,
  payload,
});

//AGENTS
export const agentsRequest = (payload) => ({
  type: types.AGENTS_REQUEST,
  payload,
});
export const agentsReceive = (payload) => ({
  type: types.AGENTS_RECEIVE,
  payload,
});

//JOB OPENNINGS
export const jobOpeninngsRequest = (payload) => ({
  type: types.JOB_OPENNINGS_REQUEST,
  payload,
});
export const jobOpeninngsReceive = (payload) => ({
  type: types.JOB_OPENNINGS_RECEIVE,
  payload,
});

// minutes consumed
export const allCompaniesMinutesRequest = () => ({
  type: types.ALL_COMPANIES_MINUTES_REQUEST,
});
export const allCompaniesMinutesReceive = (payload) => ({
  type: types.ALL_COMPANIES_MINUTES_RECEIVE,
  payload,
});

export const companySubscriptionDetailsRequest = (payload) => ({
  type: types.COMPANY_SUBSCRIPTION_DETAILS_REQUEST,
  payload,
});
export const companySubscriptionDetailsReceive = (payload) => ({
  type: types.COMPANY_SUBSCRIPTION_DETAILS_RECEIVE,
  payload,
});
export const companySubscriptionHistoryRequest = (payload) => {
  return {
    type: types.COMPANY_SUBSCRIPTION_HISTORY_LIST_REQUEST,
    payload,
  };
};
export const companySubscriptionHistoryRecieve = (payload) => {
  return {
    type: types.COMPANY_SUBSCRIPTION_HISTORY_LIST_RECIEVE,
    payload,
  };
};
export const subscriptionPlansLookupRecieve = (payload) => ({
  type: types.COMPANY_SUBSCRIPTION_PLANS_LOOKUP_RECIEVE,
  payload,
});
export const subscriptionPlansLookupRequest = () => ({
  type: types.COMPANY_SUBSCRIPTION_PLANS_LOOKUP_REQUEST,
});

export const appearOnJobSeekerRequest = (payload) => ({
  type: types.APPEAR_ON_JOB_SEEKER,
  payload,
});

export const viewInvoiceDetailsRequest = (payload) => ({
  type: types.VIEW_INVOICE_REQUEST,
  payload,
});
export const viewInvoiceDetailsRecieve = (payload) => ({
  type: types.VIEW_INVOICE_RECIEVE,
  payload,
});
export const requestResendConfirmation = data => {
  return {
    type: types.AGENT_RESEND_CONFIRMATION_REQUEST,
    payload : data
  };
};
export const receiveResendConfirmation = () => {
  return {
    type: types.AGENT_RESEND_CONFIRMATION_RECEIVE,
  };
};
export const requestResendConfirmationByCreator = data => {
  return {
    type: types.RESEND_CONFIRMATION_CREATOR_REQUEST,
    payload : data
  };
};
export const companyDetailsRequest = data => {
  return {
    type: types.COMPANY_DETAILS_REQUEST,
    payload : data
  };
};
export const companyDetailsRecieve = (payload) => {
  return {
    type: types.COMPANY_DETAILS_RECIEVE,
    payload
  };
};