import * as types from "../../types/dashboard/candidateSetup";

const INITIAL_STATE = {
  candidateProfile: {},
  candidatesGrid: {},
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CANDIDATE_PORTAL_SETUP_RECIEVE:
      return { ...state, ...action.payload };
    case types.CANDIDATES_RECEIVE:
      return { ...state, candidatesGrid: { ...action.payload } };
    case types.CANDIDATE_PROFILE_RECEIVE:
      return { ...state, candidateProfile: { ...action.payload } };
    default:
      return state;
  }
};
