import { all } from 'redux-saga/effects';
import { websiteGeneralSagasWatch } from './sagas';
import { authSagasWatch } from './auth';
import { PricingSagasWatch } from './pricing';
import announcementSagasWatch from '../dashboard/Announcement';

export function* websiteSagasWatch() {
  yield all([
    websiteGeneralSagasWatch(),
    authSagasWatch(),
    PricingSagasWatch(),
    announcementSagasWatch(),
  ]);
}
