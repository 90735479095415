import * as types from '../../types';

export const requestSubscribe = (data) => {
    return {
      type: types.FETCH_SUBSCRIBE_REQUEST,
      payload : data
    }
};


export const recieveSubscribe = (data) => {
  return {
    type: types.FETCH_SUBSCRIBE_RECEIVE,
    payload : data
  }
};



  
