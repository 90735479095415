import * as types from "../../types";

const INITIAL_STATE = {
    contact_us_data : {} ,
    contact_us_form : {}
}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.FETCH_CONTACT_US_FORM_RECEIVE:
            return { ...state, contact_us_form : {...action.payload}};
        case types.FETCH_CONTACT_US_RECEIVE:
            return { ...state, contact_us_data : {...action.payload}};
        default: return state;
    }
}