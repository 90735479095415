import React, { useState, useEffect } from "react";
import "./SideMenu.scss";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import dashboardIcon from "../../../assets/images/ic_dashboard.svg";
import registeredCompaniesIcon from "../../../assets/images/business (1).svg";
import settingsIcon from "../../../assets/images/ic_settings.svg";
import talentPoolIcon from "../../../assets/images/knight.svg";
import { Link } from "react-router-dom";
import { setSideMenu } from "../../../store/actions/dashboard/SideMenu";
import { injectIntl } from "react-intl";
import Tooltip from "@material-ui/core/Tooltip";
import { setClickedPageIndex } from "../../../store/actions/dashboard/clickedPage";
import logo from '../../../assets/images/0-01.png'

const SideMenu = ({ intl }) => {
  const lang = useSelector((state) => state.locale.lang);
  const sideMenu = useSelector((state) => state.dashboard.sideMenu);
  const activePageIndex = useSelector(
    (state) => state.dashboard.activePageIndex.index
  );
  const dispatch = useDispatch();
  const { messages } = intl;
  const sideMenuItems = [
    {
      link: "/admin/dashboard/home",
      title: messages.sideMenu.home,
      icon: dashboardIcon,
    },
    {
      link: "/admin/dashboard/registeredCompanies",
      title: messages.sideMenu.registeredCompanies,
      icon: registeredCompaniesIcon,
    },
    {
      link: "/admin/dashboard/candidates",
      title: messages.sideMenu.candidates,
      icon: talentPoolIcon,
    },
    {
      link: "/admin/dashboard/setup",
      title: messages.sideMenu.setup,
      icon: settingsIcon,
    },
  ];

  useEffect(() => {
    lang === "ar"
      ? sideMenu.sideMenuWidth === "70px"
        ? dispatch(
            setSideMenu({
              sideMenuContent: "none",
              mainMrginLeft: "40px",
              mainMarginRight: "120px",
              navbarMarginLeft: "0px",
              navbarMarginRight: "70px",
              curvedNavbLeft: "auto",
              curvedNavbRight: "3.2em",
            })
          )
        : dispatch(
            setSideMenu({
              sideMenuContent: "block",
              mainMrginLeft: "40px",
              mainMarginRight: "290px",
              navbarMarginLeft: "0px",
              navbarMarginRight: "250px",
              curvedNavbLeft: "auto",
              curvedNavbRight: "16em",
            })
          )
      : sideMenu.sideMenuWidth === "70px"
      ? dispatch(
          setSideMenu({
            sideMenuContent: "none",
            mainMrginLeft: "120px",
            mainMarginRight: "40px",
            navbarMarginLeft: "70px",
            navbarMarginRight: "0px",
            curvedNavbLeft: "3.2em",
            curvedNavbRight: "auto",
          })
        )
      : dispatch(
          setSideMenu({
            sideMenuContent: "block",
            mainMrginLeft: "290px",
            mainMarginRight: "40px",
            navbarMarginLeft: "250px",
            navbarMarginRight: "0px",
            curvedNavbLeft: "16em",
            curvedNavbRight: "auto",
          })
        );
  }, [lang]);

  const closeNav = () => {
    lang === "en"
      ? dispatch(
          setSideMenu({
            closeBtn: "none",
            openBtn: "block",
            companyLogoSideMenuHeight: "50px",
            companyLogoSideMenuWidth: "50px",
            sideMenuContent: "none",
            sideMenuContentPaddingTop: "16px",
            sideMenuContentPaddingBottom: "16px",
            sideMenuWidth: "70px",
            mainMrginLeft: "120px",
            mainMarginRight: "40px",
            navbarMarginLeft: "70px",
            navbarMarginRight: "0px",
            curvedNavbLeft: "3.2em",
            curvedNavbRight: "auto",
          })
        )
      : dispatch(
          setSideMenu({
            closeBtn: "none",
            openBtn: "block",
            companyLogoSideMenuHeight: "50px",
            companyLogoSideMenuWidth: "50px",
            sideMenuContent: "none",
            sideMenuContentPaddingTop: "16px",
            sideMenuContentPaddingBottom: "16px",
            sideMenuWidth: "70px",
            mainMrginLeft: "40px",
            mainMarginRight: "120px",
            navbarMarginLeft: "0px",
            navbarMarginRight: "70px",
            curvedNavbLeft: "auto",
            curvedNavbRight: "3.2em",
          })
        );
  };

  const openNav = () => {
    lang === "en"
      ? dispatch(
          setSideMenu({
            closeBtn: "block",
            openBtn: "none",
            companyLogoSideMenuHeight: "100px",
            companyLogoSideMenuWidth: "100px",
            sideMenuContent: "block",
            sideMenuContentPaddingTop: "8px",
            sideMenuContentPaddingBottom: "8px",
            sideMenuWidth: "250px",
            mainMrginLeft: "290px",
            mainMarginRight: "40px",
            navbarMarginLeft: "250px",
            navbarMarginRight: "0px",
            curvedNavbLeft: "16em",
            curvedNavbRight: "auto",
          })
        )
      : dispatch(
          setSideMenu({
            closeBtn: "block",
            openBtn: "none",
            companyLogoSideMenuHeight: "100px",
            companyLogoSideMenuWidth: "100px",
            sideMenuContent: "block",
            sideMenuContentPaddingTop: "8px",
            sideMenuContentPaddingBottom: "8px",
            sideMenuWidth: "250px",
            mainMrginLeft: "40px",
            mainMarginRight: "290px",
            navbarMarginLeft: "0px",
            navbarMarginRight: "250px",
            curvedNavbLeft: "auto",
            curvedNavbRight: "16em",
          })
        );
  };

  const handleListItemClick = (event, index) => {
    dispatch(setClickedPageIndex({ index }));
  };

  const renderListItem = (items) => {
    return items.map((item, index) => {
      return (
        <Link key={index} to={item.link}>
          <Tooltip title={item.title} placement="top-end">
            <ListItem
            className="listItem bold_font"
              button
              style={{
                paddingTop: sideMenu.sideMenuContentPaddingTop,
                paddingBottom: sideMenu.sideMenuContentPaddingBottom,
              }}
              selected={activePageIndex === item.link}
              onClick={(event) => handleListItemClick(event, item.link)}
            >
              <ListItemIcon>
                <img
                  src={item.icon}
                  alt="dashboard icon"
                  className="icons_img"
                />
              </ListItemIcon>
              <ListItemText
                className="bold_font"
                dir={lang === "ar" ? "rtl" : "ltr"}
                primary={item.title}
                style={{
                  display: sideMenu.sideMenuContent,
                  transition: "0.5s",
                }}
              />
            </ListItem>
          </Tooltip>
        </Link>
      );
    });
  };

  return (
    <React.Fragment>
      <div
        className="sidenav"
        id="mySidenav"
        style={{ width: sideMenu.sideMenuWidth }}
      >
        <img src={logo} alt="HRCom logo" className="sidemenu-logo" />
        <div
          className="curvedNav"
          style={{
            left: sideMenu.curvedNavbLeft,
            right: sideMenu.curvedNavbRight,
          }}
        >
          <button
            className="btn closebtn"
            onClick={closeNav}
            style={{ display: sideMenu.closeBtn }}
          >
            <ArrowBackIosIcon fontSize="small"/>
          </button>
          <button
            className="btn openBtn"
            onClick={openNav}
            style={{ display: sideMenu.openBtn }}
          >
            <ArrowForwardIosIcon fontSize="small"/>
          </button>
        </div>
        <List>{renderListItem(sideMenuItems)}</List>
      </div>
    </React.Fragment>
  );
};

const SideMenuComponent = injectIntl(SideMenu);

export default SideMenuComponent;
