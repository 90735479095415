import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/dashboard/dashboard";
import * as Actions from "../../actions/dashboard/dashboard";
import * as type from "../../types/dashboard/dashboard";
import { dispatchError } from "../../../utils/Shared";

function* requestDashboardData({ payload }) {
  try {
    const response = yield call(API.requestDashboardData, payload);
    yield put(Actions.receiveDashboardData(response.data.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* requestDashboardPlansData() {
  try {
    const response = yield call(API.requestPlansData);
    yield put(Actions.receiveDashboarPlansdData(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* requestDashboardPackagesData() {
  try {
    const response = yield call(API.requestPackagesData);
    yield put(Actions.receiveDashboardPackagesData(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export default function* DashboardSagasWatch() {
  yield takeLatest(type.DASHBOARD_DATA_REQUEST, requestDashboardData);
  yield takeLatest(type.DASHBOARD_PLANS_REQUEST, requestDashboardPlansData);
  yield takeLatest(type.DASHBOARD_PACKAGES_REQUEST, requestDashboardPackagesData);

}
