import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/lookups/lookups";
import * as ACTION from "../../actions/lookups";
import * as TYPES from "../../types/dashboard/lookups";

export function* collegeLookupRequest() {
  try {
    const response = yield call(API.collegeLookupRequest);
    yield put(ACTION.collegeLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* degreeLookupRequest() {
  try {
    const response = yield call(API.degreeLookupRequest);
    yield put(ACTION.degreeLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* jobTitleLookupRequest() {
  try {
    const response = yield call(API.jobTitleLookupRequest);
    yield put(ACTION.jobTitleLookupReceive(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* residenciesLookupRequest() {
  try {
    const response = yield call(API.residenciesLookupRequest);
    yield put(ACTION.residenciesLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* languagesWithOutEnglishLookupRequest() {
  try {
    const response = yield call(API.languagesWithOutEnglish);
    yield put(ACTION.languagesWithOutEnglishLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* skillsLookupRequest() {
  try {
    const response = yield call(API.skillsLookupsRequest);
    yield put(ACTION.skillsLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* lookupCurrencies() {
  try {
    const response = yield call(API.currencyLookup);
    yield put(ACTION.receiveCurrency(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* lookupCompanies() {
  try {
    const response = yield call(API.companieslookup);
    yield put(ACTION.companiesLookupReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* hearAboutUsLookups() {
  try {
    const response = yield call(API.getHearAboutUsList);
    yield put(ACTION.hearAboutUsListReceive(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export default function* lookupsSagas() {
  yield takeLatest(TYPES.RESIDENCIES_LOOKUP_REQUEST, residenciesLookupRequest);
  yield takeLatest(
    TYPES.LANGUAGES_WITHOUT_ENGLISH_LOOKUP_REQUEST,
    languagesWithOutEnglishLookupRequest
  );
  yield takeLatest(TYPES.SKILLS_LOOKUP_REQUEST, skillsLookupRequest);
  yield takeLatest(TYPES.COLLEGE_LOOKUP_REQUEST, collegeLookupRequest);
  yield takeLatest(TYPES.DEGREE_LOOKUP_REQUEST, degreeLookupRequest);
  yield takeLatest(TYPES.JOB_TITLE_LOOKUP_REQUEST, jobTitleLookupRequest);
  yield takeLatest(TYPES.LOOKUP_CURRENCY_REQUEST, lookupCurrencies);
  yield takeLatest(TYPES.COMPANIES_LOOKUP_REQUEST, lookupCompanies);
  yield takeLatest(TYPES.HEAR_ABOUT_US_LIST_REQUEST, hearAboutUsLookups);
}
