import { all } from "redux-saga/effects";
import registeredCompaniesSagas from "./registeredCompaniesSagas";
import authSagas from "./auth";
import CandidateProfileSetupSagasWatch from "./candidateSetup";
import lookupsSagas from "./lookups";
import DashboardSagasWatch from "./dashboard";
import promoCodesSagasWatch from "./promoCodes";
import DashboardSubscriptionsSagasWatch from './subscriptions'
export default function* dashboardSagasWatch() {
  yield all([
    registeredCompaniesSagas(),
    authSagas(),
    CandidateProfileSetupSagasWatch(),
    lookupsSagas(),
    DashboardSagasWatch(),
    promoCodesSagasWatch(),
    DashboardSubscriptionsSagasWatch()
  ]);
}
