export default {
  en: {
    poweredBy: "Powered by HRcom",
    home: "Home",
    langBtn: "عربى",
    products: "Products",
    aboutUs: "About us",
    contactUs: "Contact us",
    pricing: "Pricing",
    hideWebsiteTitle: "This page is not available at the moment",
    fqa: "FAQ",
    jobSeekerTitle: "Jobseeker",
    signIn: "Login",
    signUp: "Sign up",
    registerCompany: "Register",
    tryFree: "Try For Free",
    subscribe: "Subscribe",
    userName: "Your Name",
    paymentMethods: "The available payment methods",
    email: "Email Address",
    subscribe_header: "Subscribe Now",
    subscribe_email: "Address",
    contactModalSuccess: "Thank you for your message, we will respond shortly",
    subscribedModalSuccess: "you have been successfully subscribed",
    subscribe_sub_header:
      " And Join our newsletter to receive the latest offers. And have preceding knowledge of all that is new in the world of Recruitment and Human Resources!",
    reviews_sub_header: "See what people are saying",
    privacyPolicy: "Privacy policy",
    termsAndConditions: "Terms and conditions",
    disclaimer: "Disclaimer",
    signUpAgreeMsg:
      "By registering your company with us, you are agreeing to our ",
    multiFactorAuth: {
      enabledTitle: "Two-Factor Authentication",
      stepTwoDescription: "Open the Oracle Authenticator app on your phone and enter the generated code",
      steps: {
        1: "1. Download and install Oracle Authenticator app",
        2: "2. Using the app, scan the QR code or manually enter the following code",
      },
      title: "Enable Two-Factor Authentication",
      subTitle: "Secure your Adaa account",
      description:
        "Recommended! Add an extra layer of security for your account by enabling Two-Factor Authentication to make sure that only you can access it",
    },
    serviceSection: {
      WhatDoYouGet: "What do you get ?",
      manpowerRequest: {
        name: "Manpower Request",
        description:
          "We optimize your work efficiency by setting up roles and approval lines",
      },
      CloudArchive: {
        name: "Cloud Archive",
        description:
          " All your data is easily manageable and accessible for you through a secure cloud",
      },
      jobAdvertising: {
        name: "Job Advertising",
        description:
          "Share vacancies through your career portal and Social Media",
      },
      receivingAndSortingApplications: {
        name: " Receiving and Sorting Applications",
        description:
          "Proceed with the applications through your specific stages",
      },
      paperlessEnvironment: {
        name: "Paperless Environment",
        description:
          "Have a paperless experience and save your time and effort",
      },
      teamCollaboration: {
        name: "Team Collaboration",
        description:
          " We empower your HR team with effective tools to improve   your collaboration",
      },
      PreEmploymentScreeningVideo: {
        name: "Pre-employment Screening Video",
        description:
          " We assess applications and filter the candidates that match   the job requirements ",
      },
    },
    paginat: {
      total: "Total",
    },
    socialLogin: {
      sectionTitle: "Or continue with",
      googleBtn: "Google",
      linkedinBtn: "Linkedin",
      microsoftBtn: "Microsoft",
      prerequestionTitle: "Basic Info",
    },
    errors: {
      codeRequired: "This code is required",
      required: "This field is required",
      validEmail: "Invalid email",
      validDate: "Invalid Date",
      validPhone: "Phone is not valid",
      validDomain:
        "Allow English only, spacing and special characters are not allowed",
      validPassoword_title_tip: "Password is following these rules :",
      validPassord_min_tip: "Min. 8 characters",
      validPassword_mix: "A mixture of both uppercase and lowercase letters",
      validPassword_letters: "A mixture of letters and numbers",
      validPassword:
        "Invalid password : Min 8 characters , mixture of uppercase and lowercase letter and numbers",
      validMatching: "Password and confirm password don’t match",
      confirmPasswordMatch: "Password and confirm password don’t match",
      CVfileType: "Word or PDF are only allowed",
      fileMaxSize: "File should not exceed 3 MB",
      imageMaxSize: "Image should not exceed 10 MB",
      imagetype: "Png and JPEG are only allowed",
      invalidGPAValue: "It should be between 0 - ",
      toGreaterThanFrom: "To must be greater than from",
      promoCodeDecimals:
        "It only accepts 4 digits and maximum of 2 decimals are allowed",
      maxPercentenge: "Max 100",
      companyNameMessage: "Field accept letters and numbers",
      maxLength50: "Max Length 50",
      maxLength5_number:
        "Field accept numbers Only And Max Length 5 And not start with 0",
      maxLength4_number:
        "Field accept numbers Only And Max Length 4 And not start with 0",
      invalidWebsiteUrl:
        "Please enter a valid website URL (e.g. https://hrcom.io/)",
    },
    products_cards: {
      product1:
        "Your portal to attracting top-quality talents and retaining engaged employees with powerful automated solutions.",
      product2:
        "A qualified team is the base of the pyramid! Engage new-hires, to deepen their affiliation to the organization, and accelerate their productivity.",
    },
    about_us_content: {
      about_us_title: "About us",
      sub_content_header: "Empowerment is a rule we stick by!",
      sub_content_decription:
        "Because we strongly believe in the importance of digital transformation and its impact on achieving your business goals. Here in HRcom, we have created an HR solutions system that helps you establish an effective recruitment process and accomplish your goals with precision and accuracy.",
      mission_content:
        "To activate an effective recruitment strategy and enable human competencies to achieve their objectives and strategy.",
      vission_content:
        "To be the first in region that provides the best recruitment services and solutions.",
      mission: "Our Mission",
      vission: "Our Vision",
      us_in_details: "Us In Detail",
      us_in_details_content:
        "HRcom provides the best technical solutions related to HR automation and processes to ensure maximized productivity and operational effectiveness. Whatever the size of your enterprise, we can give you the shift in quality that you aspire to achieve.",
    },
    features_content: {
      title: "Boost your team productivity!",
      content:
        "We know how Human Resources productivity is going to boost your overall business performance so we made sure to set up our tool with these essential features.",
      feature_1_title: "Fast",
      feature_1_content:
        "We appreciate your time and efforts that’s why we provide you with full resourced tool to back you up.",
      feature_2_title: "Targeted",
      feature_2_content:
        "Aim for the right candidates! Post your job openings and customize the descriptions according to the requirements.",
      feature_3_title: "Accountable",
      feature_3_content:
        "A peer that you can count on! In each phase of your hiring process leading to your decision making, boosting your growth",
      feature_4_title: "Efficient",
      feature_4_content:
        "Organize, store and access your data instantly, and discover meaningful patterns that would support your resources ",
      fast: "Fast",
      accountable: "Accountable",
      efficient: "Efficient",
      targeted: "Targeted",
    },
    contact_us_title: "Contact us",
    contact_us_content:
      "For more information, fill out the form and we’ll be in touch.",
    shared: {
      send_again: "Send again",
      address: "Address",
      submit: "Submit",
      message: "message",
      message_placeholder: "Your message here ..",
      register: "Register",
      registeryourCompany: "Register your company",
      requestDemo: "Schedule your demo ",
      add_password_title: "Add password",
      fill_all_form: "*Please fill all fields",
      success_register:
        "A confirmation email has been sent, please check your email. If you didn't receive the confirmation email within 1 minute please click send again",
      notFoundRecords: "No records found",
      name: "Name",
      email: "Email",
      phone: "Phone",
      NoDataFound: "No Data Found",
      and: "and",
      signUpNote: "For Companies",
    },
    buttons: {
      
      resendCode:"Reset 2FA",
      confirm: "Confirm",
      back: "Back",
      enableNow: "Enable Now",
      skip: "Skip",
      continue: "Continue",
      activate: "Activate",
      deactivate: "Deactivate",
      viewInvoices: "View Invoice",
      tax: "Tax Invoice",
      print: "Print",
      download: "Download",
      cancel: "Cancel",
      submit: "Submit",
      yes: "Yes",
      no: "No",
      filter: "Filter",
      applyFilter: "Apply",
      resetSearch: "Clear All",
      notFoundRecords: "No records found",
      reset: "Reset",
      save: "Save",
      moreDetails: "More details",
      send: "send",
      uploadCv: "Upload CV",
      addSection: "Add section",
      bussinessOwner: "Business owner",
      jobSeeker: "Jobseeker",
      edit: "Edit",
      delete: "delete",
      saveSend: "Save and send",
      done: "Done",
      requestDemo: "Request a free demo",
      requestDemoBanner: "Request demo",
    },
    customSubscriptionPlan: {
      title: "Custom Your Plan",
      content:
        "Manage your team, advertise your vacancies, build your candidates data base, schedule your interviews, and more…",
      customPlanAction: "Contact us",
      trialPlanTitle: "Trail FREE",
      trialPlanCotent_part1: "14 day free trial",
      trialPlanCotent_part2: "No Fees",
      trialPlanCotent_part3: "No credit card needed",
    },
    form_controls: {
      firstName: "First name",
      lastName: "Last name",
      dateOfBirth: "Date of birth",
      residency: "Residency",
      businessEmail: "Business email",
      gender: "Gender",
      maritalStatus: "Marital status",
      phoneNumber: "Phone number",
      personalEmail: "Email",
      address: "Address",
      skillsInfoTitle: "Skills",
      profLevel: " Proficiency Level",
      languages: "Languages",
      college: "College",
      degree: "Degree",
      gpa: "GPA",
      category: "Category",
      jobTitle: "Job title",
      companyName: "Company name",
      location: "Location",
      from: "From",
      to: "To",
      description: "Description",
      addEducation: "Add education",
      toPresent: "To present",
      addWorkExperience: "Add work experience",
      editWorkExperience: "Edit work experience",
      present: "Present",
      outOf: "Out of",
      customJobTitle: "Enter job title",
      first_name: "First name",
      last_name: "Last name",
      email: "Email",
      company_name_ar: "Company Name (Ar)",
      company_name_en: "Company Name (En)",
      domain: "Domain",
      password: "Password",
      confirm_password: "Confirm password",
      hearAboutUs: "How did you hear about us?",
      websiteUrl: "Website URL",
      comment: "Comment",
      recuitBefore: "Have you ever used a digital recruitment system?",
      yes: "Yes",
      no: "No",
      systemName: "System name",
    },
    footer: {
      address: "Alnoor Way, Riyadh 12222",
    },
    viewAllRequests: {
      requestId: "Request ID",
      jobTitles: "Job title",
      vacanciesNumber: "No. of vacancies",
      joiningDate: "Joining date",
      state: "state",
      aprpoveDate: "Approved on",
      disAprpoveDate: "Disapproved on",
      jobRequistion: "Job requisition",
      summary: "Summary",
      screening: "Screening",
      tabsInprogress: "In progress",
      tabsPublished: "Published",
      tabsClosed: "Closed",
      by: "By",
      deleteRequestModalHeader: "Delete request",
      deleteRequestModalContent:
        "Are you sure you want to delete this request?",
      publishingDate: "Publishing date",
      closingDate: "Closing date",
      branch: "Branch",
      country: "Country",
      city: "City",
      status: "Status",
      totalApplicants: "Total applicants",
      applied: "New applicants",
      shortListed: "Short listed",
      interviews: "Interviews (Completed/ Scheduled)",
      rejected: "Rejected",
      offers: "Offers",
      accepted: "Accepted",
      manageCandidates: "Manage candidates",
      delayClosingDate: "Delay closing date",
      outOf: "Out of",
      waitingForApproval: "Waiting for approval",
      approvedRequest: "Approved",
      disapprovedRequest: "Disapproved",
    },
    products_content: {
      recruitment: "Recruitment system",
      onboard: "On boarding system",
      rightPlace: "You’ve come to the right place!",
    },
    auth: {
      email: "Email",
      forgetPassword: "Forgot password ?",
      forgetPasswordTitle: "Forgot password",
      successRegister: `A confirmation email has been sent, please check your email. If you didn't receive the confirmation email within 1 minute please press "Send again"`,
      password: "Password",
      confirmPassword: "Confirm password",
      passwordValidRules: "Password is following these rules:",
      passwordValidTipMin: "Min. 8 characters",
      passwordValidTipUpperLower:
        "A mixture of both uppercase and lowercase letters",
      passwordValidTipLetterNumber: "A mixture of letters and numbers",
      addPassword: "Add password",
      Reset: "Reset",
      changePassword: "Change password",
      currentPassword: "Current password",
      newPassword: "New password",
      logout: "Logout",
    },
    snackbar: {
      done: "Done Successfully",
      savedSuccessfuly: "Your data has been saved successfully",
      delayClosingDate: "Closing date has been updated successfully",
      contactModalSuccess:
        "Thank you for contacting us, your message has been sent successfully",
      moveToInterviewGrid:
        "The candidate has been transferred to interview grid successfully",
      successMoveShortlisted:
        "The candidate has been transferred to shortlisted grid successfully",
      rejectedSuccessfully: "The request has been disapproved successfully",
      emailSentSuccessfully: "Your email has been sent successfully",
      usedPlan: "can't delete this plan it was subscribed by ",
    },
    sideMenu: {
      home: "Dashboard",
      registeredCompanies: "Registered companies",
      companyDetails: "Company details",
      changePassword: "Change password",
      setup: "Settings",
      candidateSetup: "Candidate profile",
      candidates: "Talent pool",
      promoCodes: "Promo codes",
      planCustomization: "Plan customization",
      addPlanCustomization: "Add Customized Plan",
    },
    dashboard: {
      unverifiedCompanies: "Unverified companies",
      activationRate: "Activation rate",
      goGoogle: "Google analytics reports",
      registeredCompanies: "Registered companies",
      activeCompanies: "Active companies",
      deactiveCompanies: "De-active companies",
      unverifiedActiveCompanies: "Active Unverified companies",
      unverifiedDeactiveCompanies: "De-active Unverified companies",
      agents: "Agents",
      totalConsumedMinutes: "Total consumed minutes",
      currentMonthMinutes: "Current month minutes",
      candidates: "Candidates",
      hrComJobSeekers: "HRcom job seekers",
      companiesCandidates: "Companies candidates",
      subscriptions: "Subscriptions",
      active: "Active",
      package: "Package",
      soonExpired: "Soon To Expire",
      total: "Total",
      inactive: "Expired",
    },
    planCustomization: {
      planCustomization: "Plan customization",
      addCustomizedPlan: "add customized plan",
      planName: "Plan Name",
      deletePlan: "Delete Plan",
      limited: "Limited",
      unlimited: "Unlimited",
      price: "Price",
      edit: "Edit",
      delete: "Delete",
      payment_type: "Payment Type",
      paymentType: "Payment type *",
      deletePlanMessage: "Are you sure you want to delete this plan?",
      generalInfo: "General Info",
      name_ar: "Plan name (AR) *",
      name_en: "Plan name (EN) *",
      companies: "Companies",
      annual_price: "Annual price *",
      month_price: "Monthly price *",
      features: "Features",
      has_send_job_offer: "Offer approval request",
      has_screening_questions: "Candidate Screening questions",
      has_career_questioner: "Career Questioner",
      number_of_users: "Number of users *",
      number_of_jobs: "Number of jobs open monthly *",
      number_of_cvs: "Number of CVs to apply monthly *",
      number_of_minutes: "Number of minutes for the live interview monthly *",
      number_of_workflow_pipelines: "Workforce approval Request *",
      has_posting_jobs_via_socialmedia: "Posting jobs through social media",
      has_company_brief: "Company Brief",
      has_linking_your_website: "Linking your website ",
      has_brand_colors: "Branded Career Site",
      has_brand_logo: "Brand job portal with your Logo",
      all: "All",
      has_pre_screening_assessment: "Pre-screening assessment",
      has_deticated_success: "Dedicated customer success",
      has_send_email: "Send E-mail",
      has_brand_job_post: "Brand your post",
    },
    registeredCompanies: {
      registeredCompanies: "Registered companies",
      active: "Active",
      inactive: "De-active",
      action: "Action",
      sendEmail: "Send email",
      mainDetails: "Main details",
      verifyEmailHeader: "Send verification email",
      verifyEmailContent:
        "Are you sure you want to send a verification email to this agent?",
      companyLogo: "Company logo",
      companyPortal: "Company Portal",
      jobPortal: "Job Portal",
      companySize: "Company Size",
      vatNumber: "VAT Number",
      companyIndustry: "Company Industry",
      websiteUrl: "Website URL",
      saudiCompany: "Saudi Arabian Company",
      companyDomain: "Company domain",
      companyName: "Company name",
      registeredEmail: "Registered email",
      registerationDate: "Registration date",
      trial: "Trial",
      basic: "Basic",
      advanced: "Advanced",
      premium: "Premium",
      notverified: "Not Verified",
      name: "Name, domain",
      email: "Email",
      plan_type: "Plan",
      noAgents: "No. of agents",
      ActivateCompany: "Are you sure you want to activate this Company?",
      DeactivateCompany: "Are you sure you want to deactivate this Company?",
      ActivateTitle: "Activate Company",
      DeactivateTitle: "Deactivate Company",
      activated: "Activated",
      deactivated: "Deactivated",
      agents: "Agents",
      jobOpenings: "Job openings",
      agentName: "Agent name",
      agentEmail: "Agent email",
      agentPhone: "Agent phone",
      agentJobRole: "Agent job role",
      outOf: "Out of",
      monthConsumedMinutes: "Monthly consumed minutes",
      totalConsumedMinutes: "Total consumed minutes",
      totalMonthMinutes: "Total monthly minutes",
      totalMinutes: "Total minutes",
      plan: "Plan",
      subscription: "View payment process",

      acceptTermsAndConditions:
        "By registering your company with us, you are agreeing to our Terms and conditions, Privacy policy, and Disclaimer",
      errorAcceptTermsAndConditions:
        "you must agree to the terms and conditions, privacy policy and disclaimer",
    },
    login: {
      loginHeading: "Sign In",
      forgotPassword: "Forgot password ?",
    },
    forgetPassword: {
      forgetPasswordHeading: "Forget my password",
    },
    jobSeeker: {
      engProLevel: "What is your proficiency level in English?",
      personal: "Personal Info",
      skills: "Skills",
      education: "Education",
      workExperience: "Work Experience",
      confirmationDialogContent:
        "This email is registered before, Do you want to update your profile?",
      reachingGoald: "Reaching goals starts with action!",
      sub_content:
        "Start your story of success! Register now to achieve your career aspirations.",
    },
    settings: {
      generalSettings: "General settings",
      candidateProfile: "Candidate profile",
      simpleProfile: "Simple profile",
      complicatedProfile: "Complicated profile",
      promoCode: "Promocode",
    },
    candidates: {
      registeredOn: "Registered on",
      sendEmail: "Send email",
      personalInfoTitle: "Personal information",
      firstName: "First name",
      lastName: "Last name",
      dateOfBirth: "Date of birth",
      residency: "Residency",
      gender: "Gender",
      maritalStatus: "Marital status",
      contactDetails: "Contact details",
      phoneNumber: "Phone number",
      personalEmail: "Personal Email",
      address: "Address",
      male: "Male",
      female: "Female",
      skillsInfoTitle: "Skills",
      profLevel: " Proficiency Level",
      engProLevel: "What is your proficiency level in English?",
      languages: "Languages",
      profLevelInEnglish: " Proficiency level in English",
      education: "Education",
      workExperience: "Work Experience",
      college: "College",
      degree: "Degree",
      gpa: "GPA",
      category: "Category",
      jobTitle: "Job title",
      companyName: "Company name",
      location: "Location",
      from: "From",
      to: "To",
      description: "Description",
      present: "Present",
      subject: "Subject",
      body: "Body",
      advertQuestions: "Advert questions",
      currentLastSalary: "Current/Last  Salary",
      currency: "Currency",
      currenctLastPosition: "Current/Last position",
      currenctLastCompanyName: "Current/Last company name",
    },
    pricingPage: {
      headerLabel: "Easy pricing plans",
      headerContent: "asdfsdgnsl n sdl sdjkl awnwon ",
      freeTrialEndsIn: "Free trial will end in",
      freeTrialEndedIn: "Free trial ended in",
      days: "Days",
      hours: "Hours",
      day: "Day",
      minutes: "Minutes",
      seconds: "Seconds",
      subscribe: "Subscribe now",
      ends: "Free trial ends in",
      currentPlan: "current plan",
      trialPlan: "Trial Plan",
      basicPlan: "Basic plan",
      advancedPlan: "Advanced plan",
      premiumPlan: "Premium plan",
      usersNo: "Number of users",

      webcamMinutesNo: "Number of Minutes for the live interview Monthly",
      createJobsOnline: "Create jobs online (other recruiting agencies)",
      socialMedia: "Posting jobs through social media",
      BrandYourPost: "Brand your post",
      DedicatedAccountManager: "Dedicated account manager",
      careerQuestioner: "Career questionnaire",
      scoreApplicants: "Candidate Screening questions",
      workflow: "Workforce approval Request",
      CustomizePipelineStage: "Customize Pipeline stage",
      ManageRolesAndPermissions: "Manage roles and permissions",
      ESignatures: "Provide offer template and E-signatures",
      jobOfferOnline: "Offer approval request",

      PreScreeningVideoAssessment: "Pre-screening video assessment",
      PreScreeningTextAssessment: "Pre-screening text assessment",
      LiveVideoInterview: "Live Video interview",

      popularPlan: "Most Popular",
      jobOpeningsNo: "Number of Jobs open Monthly",
      cvsNo: "Number of CVs to apply Monthly",
      companyBrief: "Company Brief",
      linkWebsite: "Linking your website",
      customColor: "Branded Career Page",
      customLogo: "Branded career page with your logo",
      includeVat: "Including VAT",
      fourteenFreeDays: "14 day free trial",
      engagementWithCandidates:
        "Engagement system with your candidate (Resal, send emails)",
      subscribeBtn: "Subscribe",
      expirationDate: "Expiration date",
      expirationDateTrial: "Free",
      freeTrialDays: "day(s) free trial",
      monthly: "Monthly",
      annual: "Annual",
      save: "Save",
      SAR: "SAR",
      unlimited: "Unlimited",
      payNow: "Pay now",
      selectCard: "Select card",
      downgradeWarning:
        "Downgrading your subscription plan, may cause losing control on some added data",
      cancelSubscription: "Cancel subscription",
      cancelSubscriptionModalHeader:
        "Are you sure you want to cancel your subscription?",
      cancelSubscriptionModalContent:
        "By Cancellation your account will be deactivated",
      youHave: "You have",
      refundStatement:
        "SAR, will be refunded to you according to the HRcom policy",
      myWallet: "My wallet",
      walletUseNote: "will be used in next transactions",
      renew: "Renew",
      unsubscribe: "Unsubscribe",
      perMonth: "per month",
      select: "Select",
      trialName: "Trial",
      free: "Free",
      billedAnnually: "Billed annually",
      billedMonthly: "Billed monthly",
      users: "users",
      jobsMonthly: "jobs open monthly",
      numberOfCvs: "number of CVs to apply monthly",
      jobsTrial: "jobs open",
      numberOfCvsTrial: "number of CVs to apply",
      usersTrial: "users",
      startTrial: "Start Trial",
      googleSearch: "Jobs on Google search",
      overview: "Overview",
      companyBranding: "Company Branding",
      matrix: "Authority Matrix",
      tools: "Assessment Tools",
      jobPost: "Job Posting",
      customerSuccess: "Customer Success",
      advancedFeatures: "Advanced Features",
      sendSMSToCandidate: "Send SMS Messages to candidates",
      singleSignOn: "Single sign-on (SSO) ",
    },
    subscriptions: {
      trial: "Trail",
      basic: "Basic",
      advance: "Advance",
      premium: "Premium",
      myWallet: "My wallet",
      SAR: "SAR",
      appearOnJobSeeker: "Appear on Jobseeker",
    },
    promoCodes: {
      addPromoCode: "Add promo code",
      editPromoCode: "Edit promo code",
      promoCodes: "Promo code",
      startDate: "Start date",
      endDate: "End date",
      sendTo: "Send To",
      usedBy: " Used By",
      selectPromoCodeType: "Select promo code type",
      percentage: "Percentage",
      fixedAmout: "Fixed amount",
      discountValue: "Discount value",
      maxLimitValue: "Maximum limit value",
      deletePromoCode: "Delete promo code",
      deletePromoCodeContent:
        "Are you sure you want to delete this promo code?",
      sendPromoCode: "Send promo code",
      companies: "Companies",
      clients: "Clients",
    },
    announcement: {
      annonceDate: "Announce Email Date ",
      title: "Announce Email Subject",
      body: "Announce Email Content",
      announcement: "Add Announcement",
    },
    ourStory: {
      title: "Our Story",
      description_1:
        "Recruitment operations have always had challenges in managing and organizing them, and because we went through these challenges ourselves; We created HRcom system. We faced many problems in the recruitment processes, especially in using E-mails to recruit. We were facing many problems regarding sourcing for candidates and communication with them, and in sorting, saving, and returning to resumes. We also spent a lot on some sites to sign up and complete these procedures.",
      description_2:
        "Afterward, the problem got bigger and inspired us to look for a better solution. We search and concluded that all employment system around the world was established on a technical basis. Therefore, we launched a recruitment system that provides all the needs of the recruitment process including self- employment, building a database of candidates, conducting interviews, and sending the job offers.",
      description_3:
        "In building this system, we wanted to provide an easy and flexible recruitment process for the recruiters, and we also wanted to provide a smooth and flexible experience for the job seekers in their looking for a job experience. So that in the end, the impact of this experience would be reflected positively on the reputation of the establishment.",
      description_4: "This is our story..",
      description_5: "Try HRcom for Free and start your story with us!",
    },
  },
};
