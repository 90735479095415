import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import dashboardIcon from "../../../assets/images/ic_dashboard.svg";
import registeredCompaniesIcon from '../../../assets/images/business (1).svg'
import settingsIcon from "../../../assets/images/ic_settings.svg";
import talentPoolIcon from "../../../assets/images/knight.svg";
import { setClickedPageIndex } from "../../../store/actions/dashboard/clickedPage";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import messages from "../../../assets/Local/messages";
import logo from "../../../assets/images/0-01.png";
import "./DrawerMenu.scss";

const DrawerMenu = ({ intl }) => {
  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
  });
  const activePageIndex = useSelector(
    (state) => state.dashboard.activePageIndex.index
  );
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const message = messages[lang];
  const classes = useStyles();
  const sideMenuItems = [
    {
      link: "/admin/dashboard/home",
      title: message.sideMenu.home,
      icon: dashboardIcon,
    },
    {
      link: "/admin/dashboard/registeredCompanies",
      title: message.registeredCompanies.registeredCompanies,
      icon: registeredCompaniesIcon,
    },
    {
      link: "/admin/dashboard/candidates",
      title: message.sideMenu.candidates,
      icon: talentPoolIcon,
    },
    {
      link: "/admin/dashboard/setup",
      title: message.sideMenu.setup,
      icon: settingsIcon,
    },
  ];
  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const handleListItemClick = (event, index, name) => {
    toggleDrawer("left", false);
    setState({ ...state, left: false });
    dispatch(setClickedPageIndex({ index }));
  };

  const renderSideMenuList = (items) => {
    return items.map((item, index) => {
      return (
        <Link key={index} to={item.link}>
          <ListItem
            className="listItem bold_font"
            button
            selected={activePageIndex === index}
            onClick={(event) => handleListItemClick(event, index)}
          >
            <ListItemIcon>
              <img
                src={item.icon}
                alt={item.title}
                className="my_interviews_icon icons_img"
              />
            </ListItemIcon>
            <ListItemText primary={item.title} className="bold_font" />
          </ListItem>
        </Link>
      );
    });
  };

  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <img src={logo} alt="HRCom logo" className="sidemenu-logo m-3" />
      <List component="nav" aria-label="main mailbox folders">
        {renderSideMenuList(sideMenuItems)}
      </List>
    </div>
  );
  return (
    <div>
      <Button className="drawerShown" onClick={toggleDrawer("left", true)}>
        <MenuIcon />
      </Button>
      <Drawer
        open={state.left}
        onClose={toggleDrawer("left", false)}
        dir={lang === "ar" ? "rtl" : "ltr"}
        className={lang === "ar" ? "text-right" : "text-left"}
      >
        {sideList("left")}
      </Drawer>
    </div>
  );
};

export default DrawerMenu;
