import { combineReducers } from "redux";
import homeReducer from "./HomeReduder";
import SubscribeReducer from "./SubscribeReducer";
import aboutUsReducer from "./aboutUsReducer";
import contactUsReducer from "./contactUsReducer";
import registration from "./registration";
import jobSeeker from "./jobSeeker";
import pricing from "./pricing";
import modalState from "./modalReducer";
import companyPortal from "./companyPortalSetup";


export default combineReducers({
  homeContent: homeReducer,
  subscribe: SubscribeReducer,
  aboutUs: aboutUsReducer,
  contactUs: contactUsReducer,
  registration,
  jobSeeker,
  pricing,
  modalState,
  companyPortal
});
