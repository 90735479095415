export const SET_CURRENT_LANG = 'SET_CURRENT_LANG';
export const GET_CURRENT_LANG = 'GET_CURRENT_LANG';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

// saga
export const FETCH_HERO_REQUEST = 'FETCH_HERO_REQUEST';
// api
export const FETCH_HERO_RECEIVE = 'FETCH_HERO_RECEIVE';

export const FETCH_INFO_REQUEST = 'FETCH_INFO_REQUEST';
export const FETCH_INFO_RECEIVE = 'FETCH_INFO_RECEIVE';

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_RECEIVE = 'FETCH_PRODUCTS_RECEIVE';

export const FETCH_NEWS_REQUEST = 'FETCH_NEWS_REQUEST';
export const FETCH_NEWS_RECEIVE = 'FETCH_NEWS_RECEIVE';

export const FETCH_FEATURES_REQUEST = 'FETCH_FEATURES_REQUEST';
export const FETCH_FEATURES_RECEIVE = 'FETCH_FEATURES_RECEIVE';

export const FETCH_REVIEWS_REQUEST = 'FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_RECEIVE = 'FETCH_REVIEWS_RECEIVE';

export const FETCH_QUESTIONS_REQUEST = 'FETCH_QUESTIONS_REQUEST';
export const FETCH_QUESTIONS_RECEIVE = 'FETCH_QUESTIONS_RECEIVE';

export const FETCH_SUBSCRIBE_REQUEST = 'FETCH_SUBSCRIBE_REQUEST';
export const FETCH_SUBSCRIBE_RECEIVE = 'FETCH_SUBSCRIBE_RECEIVE';

export const FETCH_ABOUTUS_REQUEST = 'FETCH_ABOUTUS_REQUEST';
export const FETCH_ABOUTUS_RECEIVE = 'FETCH_ABOUTUS_RECEIVE';

export const FETCH_PRODUCT_DETAILS_REQUEST = 'FETCH_PRODUCT_DETAILS_REQUEST';
export const FETCH_PRODUCT_DETAILS_RECEIVE = 'FETCH_PRODUCT_DETAILS_RECEIVE';

export const FETCH_NEWS_DETAILS_REQUEST = 'FETCH_NEWS_DETAILS_REQUEST';
export const FETCH_NEWS_DETAILS_RECEIVE = 'FETCH_NEWS_DETAILS_RECEIVE';

export const FETCH_CONTACT_US_FORM_REQUEST = 'FETCH_CONTACT_US_FORM_REQUEST';
export const FETCH_CONTACT_US_FORM_RECEIVE = 'FETCH_CONTACT_US_FORM_RECEIVE';
export const FETCH_CONTACT_US_REQUEST = 'FETCH_CONTACT_US_REQUEST';
export const FETCH_CONTACT_US_RECEIVE = 'FETCH_CONTACT_US_RECEIVE';

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const REGISTRATION_RECEIVE = 'REGISTRATION_RECEIVE';

export const SEND_AGAIN_EMAIL_REQUEST = 'SEND_AGAIN_EMAIL_REQUEST';
export const SEND_AGAIN_EMAIL_RECEIVE = 'SEND_AGAIN_EMAIL_RECEIVE';

export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
export const CONFIRM_EMAIL_RECEIVE = 'CONFIRM_EMAIL_RECEIVE';
export const CONFIRM_EMAIL_WITHOUT_PASSWORD_REQUEST = 'CONFIRM_EMAIL_WITHOUT_PASSWORD_REQUEST';

export const SET_SIDE_MENU = 'SET_SIDE_MENU';

export const REQUEST_GET_CANDIDATE_SETUP = 'REQUEST_GET_CANDIDATE_SETUP';
export const RECEIVE_GET_CANDIDATE_SETUP = 'RECEIVE_GET_CANDIDATE_SETUP';

export const REQUEST_IS_CANDIDATE_REGISTRED_BEFORE =
  'REQUEST_IS_CANDIDATE_REGISTRED_BEFORE';
export const RECEIVE_IS_CANDIDATE_REGISTRED_BEFORE =
  'RECEIVE_IS_CANDIDATE_REGISTRED_BEFORE';

export const REQUEST_ADD_JOB_SEEKER = 'REQUEST_ADD_JOB_SEEKER';

export const IS_MODAL_OPEN = 'IS_MODAL_OPEN';
export const ADD_ANNOUNCEMENT_REQUEST = 'ADD_ANNOUNCEMENT_REQUEST';

export const REQUEST_NEW_DEMO = "REQUEST_NEW_DEMO"
export const ADD_EDIT_OFFICE365_INTEGRATION_REQUEST = "ADD_EDIT_OFFICE365_INTEGRATION_REQUEST";