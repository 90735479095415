import { combineReducers } from "redux";
import loader from "./Loader";
import locale from "./Lang";
import snackbar from "./Snackbar";
import website from "./website";
import dashboard from "./dashboard";
import lookups from "./Lookups";


export default combineReducers({
  locale,
  loading: loader,
  snackbar,
  lookups,
  website,
  dashboard
});
