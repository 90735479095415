import { axiosInstance } from "../config";

let handlerEnabled = true;

const candidatePortalSetup = async () => {
  return await axiosInstance.get(`/admin/setup/candidate_profile_mode`, {
    handlerEnabled,
  });
};
const saveCandidatePortalSetup = async (data) => {
  return await axiosInstance.put(`/admin/setup/candidate_profile_mode`, data, {
    handlerEnabled,
  });
};

const candidatesRequest = async (pageNumber) => {
  let params = {
    page_number: pageNumber ? pageNumber : 1,
    page_size: 10,
  };
  return await axiosInstance.get("/hrcomsite/JobSeeker", {
    params,
    handlerEnabled,
  });
};

const requestCandidateProfile = async (candidate_id) => {
  return await axiosInstance.get(`/admin/candidates/${candidate_id}`, {
    handlerEnabled,
  });
};
const requestSendEmail = async (data) => {
  return await axiosInstance.put(
    `hrcomsite/JobSeeker/send_candidateEmail`,
    data,
    { handlerEnabled }
  );
};

export default {
  candidatePortalSetup,
  saveCandidatePortalSetup,
  candidatesRequest,
  requestCandidateProfile,
  requestSendEmail,
};
