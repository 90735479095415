export const COLLEGE_LOOKUP_REQUEST = "COLLEGE_LOOKUP_REQUEST";
export const COLLEGE_LOOKUP_RECEIVE = "COLLEGE_LOOKUP_RECEIVE";
export const DEGREE_LOOKUP_REQUEST = "DEGREE_LOOKUP_REQUEST";
export const DEGREE_LOOKUP_RECEIVE = "DEGREE_LOOKUP_RECEIVE";
export const JOB_TITLE_LOOKUP_REQUEST = "JOB_TITLE_LOOKUP_REQUEST";
export const JOB_TITLE_LOOKUP_RECEIVE = "JOB_TITLE_LOOKUP_RECEIVE";
export const RESIDENCIES_LOOKUP_REQUEST = "RESIDENCIES_LOOKUP_REQUEST";
export const RESIDENCIES_LOOKUP_RECEIVE = "RESIDENCIES_LOOKUP_RECEIVE";
export const LANGUAGES_WITHOUT_ENGLISH_LOOKUP_REQUEST =
  "LANGUAGES_WITHOUT_ENGLISH_LOOKUP_REQUEST";
export const LANGUAGES_WITHOUT_ENGLISH_LOOKUP_RECEIVE =
  "LANGUAGES_WITHOUT_ENGLISH_LOOKUP_RECEIVE";
export const SKILLS_LOOKUP_REQUEST = "SKILLS_LOOKUP_REQUEST";
export const SKILLS_LOOKUP_RECIEVE = "SKILLS_LOOKUP_RECIEVE";
export const LOOKUP_CURRENCY_REQUEST = 'LOOKUP_CURRENCY_REQUEST';
export const LOOKUP_CURRENCY_RECEIVE = 'LOOKUP_CURRENCY_RECEIVE';
export const COMPANIES_LOOKUP_REQUEST = "COMPANIES_LOOKUP_REQUEST";
export const COMPANIES_LOOKUP_RECEIVE = "COMPANIES_LOOKUP_RECEIVE";
export const HEAR_ABOUT_US_LIST_REQUEST = 'HEAR_ABOUT_US_LIST_REQUEST'
export const HEAR_ABOUT_US_LIST_RECEIVE = 'HEAR_ABOUT_US_LIST_RECEIVE'