import { axiosInstance } from '../config';
let handlerEnabled = true;
const addAnnouncement = async (data) => {
  return await axiosInstance.post('/hrcomsite/annoncements/add', data, {
    handlerEnabled,
  });
};

export default {
  addAnnouncement,
};
