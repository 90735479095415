import * as types from "../types/dashboard/lookups";

export const collegeLookupRequest = () => ({
  type: types.COLLEGE_LOOKUP_REQUEST,
});
export const collegeLookupReceive = (payload) => ({
  type: types.COLLEGE_LOOKUP_RECEIVE,
  payload,
});

export const degreeLookupRequest = () => ({
  type: types.DEGREE_LOOKUP_REQUEST,
});
export const degreeLookupReceive = (payload) => ({
  type: types.DEGREE_LOOKUP_RECEIVE,
  payload,
});
export const jobTitleLookupRequest = () => ({
  type: types.JOB_TITLE_LOOKUP_REQUEST,
});
export const jobTitleLookupReceive = (payload) => ({
  type: types.JOB_TITLE_LOOKUP_RECEIVE,
  payload,
});

export const languagesWithOutEnglishLookupRequest = () => ({
  type: types.LANGUAGES_WITHOUT_ENGLISH_LOOKUP_REQUEST,
});
export const languagesWithOutEnglishLookupReceive = (payload) => ({
  type: types.LANGUAGES_WITHOUT_ENGLISH_LOOKUP_RECEIVE,
  payload,
});

export const residenciesLookupRequest = () => ({
  type: types.RESIDENCIES_LOOKUP_REQUEST,
});
export const residenciesLookupReceive = (payload) => ({
  type: types.RESIDENCIES_LOOKUP_RECEIVE,
  payload,
});

export const skillsLookupRequest = (payload) => ({
  type: types.SKILLS_LOOKUP_REQUEST,
  payload,
});
export const skillsLookupReceive = (payload) => ({
  type: types.SKILLS_LOOKUP_RECIEVE,
  payload,
});

export const requestCurrency = () => ({
  type: types.LOOKUP_CURRENCY_REQUEST
});

export const receiveCurrency = data => ({
  type: types.LOOKUP_CURRENCY_RECEIVE,
  payload : data
});

export const companiesLookupRequest = () => ({
  type: types.COMPANIES_LOOKUP_REQUEST,
});
export const companiesLookupReceive = (payload) => ({
  type: types.COMPANIES_LOOKUP_RECEIVE,
  payload,
});

export const hearAboutUsListRequest = () => ({
  type: types.HEAR_ABOUT_US_LIST_REQUEST,
});
export const hearAboutUsListReceive = (payload) => ({
  type: types.HEAR_ABOUT_US_LIST_RECEIVE,
  payload,
});
