import * as types from "../../types/dashboard/registeredCompanies";

const INITIAL_STATE = {
  grid: {},
  plans: [],
  companyDetails: {},
  companiesMinutes: {},
  subscriptionDetails: {},
  subscription_history: null,
  invoice: {},
  showInvoice: false,
  companyMainDetails: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REGISTERED_COMPANIES_RECEIVE:
      return { ...state, grid: { ...action.payload } };
    case types.AGENTS_RECEIVE:
      return { ...state, grid: { ...action.payload } };
    case types.TABS_COUNT_RECEIVE:
      return { ...state, companyDetails: { ...action.payload } };
    case types.JOB_OPENNINGS_RECEIVE:
      return { ...state, grid: { ...action.payload } };
    case types.ALL_COMPANIES_MINUTES_RECEIVE:
      return { ...state, companiesMinutes: { ...action.payload } };
    case types.COMPANY_SUBSCRIPTION_DETAILS_RECEIVE:
      return { ...state, subscriptionDetails: { ...action.payload } };
    case types.COMPANY_SUBSCRIPTION_PLANS_LOOKUP_RECIEVE:
      return { ...state, plans: action.payload };
    case types.COMPANY_SUBSCRIPTION_HISTORY_LIST_RECIEVE:
      return {
        ...state,
        subscription_history: action.payload,
      };
    case types.VIEW_INVOICE_RECIEVE:
      return {
        ...state,
        invoice: action.payload.data,
        showInvoice: action.payload.showInvoice,
      };
      case types.AGENT_RESEND_CONFIRMATION_RECEIVE:
      return {
        ...state,
        agents: action.payload,
      };
    case types.COMPANY_DETAILS_RECIEVE:
      return {
        ...state,
        companyMainDetails: action.payload,
      };
    default:
      return state;
  }
};
