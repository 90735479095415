import store from "../store";
import { getURLParams } from "./Shared";
export const companyPortalBaseURL = (domain) => {
  return `https://${domain}${process.env.REACT_APP_COMPANY_BASE_URL}`;
};
export const companyPortalForgetPasswordPage = () => {
  const locale = store.getState().locale;
  const domain = getURLParams("domain");
  return `${companyPortalBaseURL(domain)}/forgetPassword?locale=${locale.lang}`;
};
export const candidatesPortalLink = () => {
  const locale = store.getState().locale;
  return `${process.env.REACT_APP_JOB_SEEKER_URL}?locale=${locale.lang}`

};
export const getEnv = () => {
 return `.${process.env.REACT_APP_ENV}`;
};

export function redirectToCalendarOffice365(domain, redirect_url) {
  return `${companyPortalBaseURL(domain)}/${redirect_url}`;
}

export const companyPortalRedirectionPages = {
  officeCalendar: "AddEditOffice365",
};

export const getCandidatePortalHome = (domain) => {
 return `https://${domain}${process.env.REACT_APP_CANDIDATE_PORTAL_BASE_URL}`
};
