import React, { Suspense } from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import history from "./History";
// ========= Components =========
import * as LazyComponent from "../utils/LazyLoaded";
import { Loader } from "components/Loader/Loader";
import { PrivateRoute } from "dashboard/components/PrivateRoute/PrivateRoute";

const Routes = (
  <Suspense fallback={<Loader />}>
    <Router history={history}>
      <Switch>
        {/* Website */}
        <LazyComponent.RedirectCompany path="/redirect-company" />
        <LazyComponent.CalendarOffice365 path="/calendar-office365" exact />
        <LazyComponent.ConfirmEmail path="/ConfirmEmail" exact />
        <LazyComponent.ConfirmEmailWithoutPassword path="/ConfirmEmail-withoutPassword" exact />
        <LazyComponent.SuccessRegisteration path="/success" exact />
        <LazyComponent.RequestDemo path="/demo-request" exact />
        {/* <LazyComponent.Home path="/" exact /> */}
        {/* <LazyComponent.NewRequestDemo path="/request-demo" exact /> */}

        {/* <Route exact path="/linkedin" component={LinkedInPopUp} /> */}
        <LazyComponent.CompanyLogin path="/companyLogin" exact />
        <LazyComponent.Registration path="/registration" exact />
        <LazyComponent.TwoFactorAuthentication path="/2fa" exact />
        <LazyComponent.TwoFactorAuthenticationEnabled path="/2fa-enabled" exact />
        {/* <LazyComponent.CompanyForgetPassword
          path="/companyForgetPassword"
          exact
        /> */}
        {/* <LazyComponent.Pricing path="/pricing" exact /> */}
        {/* <LazyComponent.JobSeeker path="/jobSeeker" exact /> */}
        {/* <LazyComponent.About path="/About-us" exact /> */}
        {/* <LazyComponent.ContactUs path="/Contact-us" exact /> */}
        {/* <LazyComponent.NewsDetails path="/news/:id" exact /> */}
        {/* <LazyComponent.ProductDetails path="/products/:id" exact /> */}
        {/* <LazyComponent.AllProducts path="/products" exact /> */}
        {/* <LazyComponent.FAQ path="/FAQ" exact /> */}
        {/* <LazyComponent.PrivacyPolicy path="/privacyPolicy" exact /> */}
        {/* <LazyComponent.TermsAndConditions path="/termsAndConditions" exact /> */}
        {/* <LazyComponent.Disclaimer path="/disclaimer" exact /> */}

        {/* Dashbord */}
        <PrivateRoute
          path="/admin/dashboard/home"
          exact
          component={LazyComponent.DashboardContainer}
        />
        <PrivateRoute
          path="/admin/dashboard/registeredCompanies"
          component={LazyComponent.RegisteredCompanies}
        />
        <PrivateRoute
          path="/admin/dashboard/login"
          component={LazyComponent.Login}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/forgetPassword"
          component={LazyComponent.ForgotPassword}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/resetPassword"
          component={LazyComponent.ResetPassword}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/changePassword"
          component={LazyComponent.ChangePassword}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/companyDetails/:id?"
          component={LazyComponent.CompanyDetails}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/setup"
          component={LazyComponent.Setup}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/candidateSetup"
          component={LazyComponent.CandidateSetup}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/candidates"
          component={LazyComponent.Candidates}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/candidateProfile/:id"
          component={LazyComponent.CandidateProfile}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/promoCodes"
          component={LazyComponent.PromoCodeList}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/promoCode/:id?"
          component={LazyComponent.AddEditPromoCode}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/planCustomization"
          exact
          component={LazyComponent.CustomPlan}
        />
        <PrivateRoute
          path="/admin/dashboard/PlanCustomization/add"
          exact
          component={LazyComponent.AddEditPlanCustomization}
        />
        <PrivateRoute
          path="/admin/dashboard/PlanCustomization/edit/:id?"
          exact
          component={LazyComponent.AddEditPlanCustomization}
        />
        <PrivateRoute
          path="/admin/dashboard/publishAnnouncement"
          exact
          component={LazyComponent.publishAnnouncement}
        />
        <PrivateRoute
          path="/invoices"
          exact
          component={LazyComponent.Invoices}
          permission={["SETUP"]}
        />
        <LazyComponent.UnavaliablePages
          path="*"
          title="This page doesn't exist..."
          exact
        />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  </Suspense>
);

export default Routes;
