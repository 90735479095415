import * as types from "../types/dashboard/lookups";

const INITIAL_STATE = {
  languagesWithoutEnglish: [],
  college: [],
  degree: [],
  jobTitle: [],
  residencies: [],
  skills: [],
  currencies: [],
  companies: [],
  hearAboutUs: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.COLLEGE_LOOKUP_RECEIVE:
      return { ...state, college: action.payload };
    case types.DEGREE_LOOKUP_RECEIVE:
      return { ...state, degree: action.payload };
    case types.JOB_TITLE_LOOKUP_RECEIVE:
      return { ...state, jobTitle: action.payload };
    case types.RESIDENCIES_LOOKUP_RECEIVE:
      return { ...state, residencies: action.payload };
    case types.LANGUAGES_WITHOUT_ENGLISH_LOOKUP_RECEIVE:
      return { ...state, languagesWithoutEnglish: action.payload };
    case types.SKILLS_LOOKUP_RECIEVE:
      return { ...state, skills: action.payload };
    case types.LOOKUP_CURRENCY_RECEIVE:
      return { ...state, currencies: [...action.payload] };
    case types.COMPANIES_LOOKUP_RECEIVE:
      return { ...state, companies: [...action.payload] };
    case types.HEAR_ABOUT_US_LIST_RECEIVE:
      return { ...state, hearAboutUs: [...action.payload] };
    default:
      return state;
  }
};
