import * as types from "../../types";

export const requestGetCandidateProfileSetup = () => {
  return {
    type: types.REQUEST_GET_CANDIDATE_SETUP,
  };
};

export const receiveGetCandidateProfileSetup = (payload) => {
  return {
    type: types.RECEIVE_GET_CANDIDATE_SETUP,
    payload,
  };
};

export const requestIsCandidateRegisteredBefore = (payload) => {
  return {
    type: types.REQUEST_IS_CANDIDATE_REGISTRED_BEFORE,
    payload
  };
};

export const receiveIsCandidateRegisteredBefore = (payload) => {
  return {
    type: types.RECEIVE_IS_CANDIDATE_REGISTRED_BEFORE,
    payload,
  };
};

export const requestAddJobSeeker = (payload) => {
  return {
    type: types.REQUEST_ADD_JOB_SEEKER,
    payload,
  };
};
