import React from "react";

//WEBSITE
export const Home = React.lazy(() => import("../website/containers/Home/Home"));
export const About = React.lazy(() =>
  import("../website/containers/About/About")
);
export const RequestDemo = React.lazy(() =>
  import("../website/containers/RequestDemo/RequestDemo")
);
export const NewRequestDemo = React.lazy(() =>
  import("../website/containers/NewRequestDemo/NewRequestDemo")
);
export const ContactUs = React.lazy(() =>
  import("../website/containers/ContactUs/ContactUs")
);
export const ProductDetails = React.lazy(() =>
  import("../website/containers/ProductDetails/ProductDetails")
);
export const NewsDetails = React.lazy(() =>
  import("../website/containers/NewsDetails/NewsDetails")
);
export const Registration = React.lazy(() =>
  import("../website/containers/Registeration/Registration")
);
export const SuccessRegisteration = React.lazy(() =>
  import("../website/containers/SuccessRegister/SuccessRegister")
);
export const AllProducts = React.lazy(() =>
  import("../website/containers/AllProducts/AllProducts")
);
export const ConfirmEmail = React.lazy(() =>
  import("../website/containers/ConfirmEmail/ConfirmEmail")
);
export const ConfirmEmailWithoutPassword = React.lazy(() =>
  import(
    "../website/containers/ConfirmEmailWithoutPassword/ConfirmEmailWithoutPassword"
  )
);
export const JobSeeker = React.lazy(() =>
  import("../website/containers/JobSeeker/JobSeeker")
);
export const NotFound = React.lazy(() =>
  import("../components/NotFound/NotFound")
);
export const CompanyLogin = React.lazy(() =>
  import("../website/containers/Auth/Login/Login")
);
export const CompanyForgetPassword = React.lazy(() =>
  import("../website/containers/Auth/ForgetPassword/ForgetPassword")
);
export const PrivacyPolicy = React.lazy(() =>
  import("../website/components/PrivacyPolicy/PrivacyPolicy")
);
export const TermsAndConditions = React.lazy(() =>
  import("../website/components/TermsAndConditions/TermsAndConditions")
);
export const Disclaimer = React.lazy(() =>
  import("../website/components/Disclaimer/Disclaimer")
);
export const Pricing = React.lazy(() =>
  import("../website/containers/PricingPage/PricingPage")
);
export const FAQ = React.lazy(() => import("../website/containers/FAQ/FAQ"));
export const CalendarOffice365 = React.lazy(() =>
  import("../website/containers/CalendarOffice365/CalendarOffice365")
);
export const UnavaliablePages = React.lazy(() =>
  import("../website/containers/UnavaliablePages/UnavaliablePages")
);
export const TwoFactorAuthentication = React.lazy(() => import("../website/containers/TwoFactorAuthentication/TwoFactorAuthentication"));
export const TwoFactorAuthenticationEnabled = React.lazy(() => import("../website/containers/TwoFactorAuthentication/TwoFactorAuthenticationEnabled"));

//DASHBOARD
export const DashboardContainer = React.lazy(() =>
  import("../dashboard/containers/DashboardContainer/DashboardContainer")
);
export const RegisteredCompanies = React.lazy(() =>
  import("../dashboard/containers/RegisteredCompanies/RegisteredCompanies")
);
export const ForgotPassword = React.lazy(() =>
  import("../dashboard/containers/Auth/ForgotPassword/ForgotPassword")
);
export const ResetPassword = React.lazy(() =>
  import("../dashboard/containers/Auth/ResetPassword/ResetPassword")
);
export const ChangePassword = React.lazy(() =>
  import("../dashboard/containers/Auth/ChangePassword/ChangePassword")
);
export const Login = React.lazy(() =>
  import("../dashboard/containers/Auth/Login/Login")
);
export const CompanyDetails = React.lazy(() =>
  import(
    "../dashboard/containers/CompanyDetails/CompanyDetailsContainer/CompanyDetailsContainer"
  )
);
export const Setup = React.lazy(() =>
  import("../dashboard/containers/Setup/Setup")
);
export const CandidateSetup = React.lazy(() =>
  import("../dashboard/containers/CandidateSetup/CandidateSetup")
);
export const Candidates = React.lazy(() =>
  import("../dashboard/containers/CandidatesGrid/CandidatesGrid")
);
export const CandidateProfile = React.lazy(() =>
  import("../dashboard/containers/CandidateProfile/CandidateProfile")
);

export const PromoCodeList = React.lazy(() =>
  import("../dashboard/containers/PromoCodes/PromoCodesList")
);

export const AddEditPromoCode = React.lazy(() =>
  import("../dashboard/containers/PromoCodes/AddEditPromoCode")
);
export const CustomPlan = React.lazy(() =>
  import("../dashboard/containers/CustomPlan/CustomPlan")
);
export const AddEditPlanCustomization = React.lazy(() =>
  import("../dashboard/containers/AddEditCustomPlan/AddEditCustomPlan")
);
export const publishAnnouncement = React.lazy(() =>
  import("../dashboard/containers/Announcement/Add")
);
export const RedirectCompany = React.lazy(() =>
  import("../containers/Login/Login")
);
export const Invoices = React.lazy(() =>
  import("../dashboard/containers/Invoices/SubscriptionHistoryPagination")
);
