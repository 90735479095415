import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import Theme from "./Theme";
import { Provider } from "react-redux";
import store from "./store";
import { addLocaleData } from "react-intl";
import * as en from "react-intl/locale-data/en";
import * as ar from "react-intl/locale-data/ar";
import { Loader } from "components/Loader/Loader";
addLocaleData(en);
addLocaleData(ar);
const WithMsal = () => {
  const MsalWrapper = React.lazy(() =>
    import("components/MsalProvider/MsalProvider")
  );
  return (
    <Suspense fallback={<Loader />}>
      <MsalWrapper>
        <Provider store={store}>
          <Theme />
        </Provider>
      </MsalWrapper>
    </Suspense>
  );
};

ReactDOM.render(
  (window.location.href.includes("registration")||
  window.location.href.includes("redirect-company")||
  window.location.href.includes("companyLogin")) ? (
    <WithMsal />
  ) : (
    <Provider store={store}>
      <Theme />
    </Provider>
  ),
  document.querySelector("#root")
);
