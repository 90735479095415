import store from "../../store";
import { loader } from "../../store/actions/loader";
import * as jwt_decode from "jwt-decode";
import  Auth  from '../../utils/Auth';
import History from 'routes/History';


export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    document.body.classList.add("loading-indicator");
    store.dispatch(loader(true));
    request.headers["Accept-Language"] =
      localStorage.getItem("lang") === "ar" ? "ar-SA" : "en-US";
    request.headers["Content-Type"] = "application/json";
    const token = localStorage.getItem("token");
    if (token) request.headers["Authorization"] = `Bearer ${token}`;
  }
  return request;
};

export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
    store.dispatch(loader(false));
    document.body.classList.remove("loading-indicator");
  }
  return response;
};

export const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    document.body.classList.remove("loading-indicator");
    store.dispatch(loader(false));
    if (error.response.status === 401) {
      Auth.logout();
      History.push('/admin/dashboard/login');
    }
  }
  return Promise.reject({ ...error });
};

export function getDecodedAccessToken(token) {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
}