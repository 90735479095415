import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/website/pricing";
import * as ACTIONS from "../../actions/website/pricing";
import * as type from "../../types/website/pricing";
import { static_features_plans, dispatchError } from "utils/Shared";

function* requestSubscriptionPlans() {
  try {
    const response = yield call(API.requestSubscriptionPlans);
    const subscriptionPlans = response.data.data.map(
      (item) => {
        if (item.type === "basic")
          return { ...item, ...static_features_plans["basic"] };
        if (item.type === "advance")
          return { ...item, ...static_features_plans["advance"] };
        else return { ...item, ...static_features_plans["premium"] };
      }
    );
    yield put(
      ACTIONS.receiveSubscriptionPlans([...subscriptionPlans])
    );
  } catch (error) {
    console.log(error)
    dispatchError(error.response.data);
  }
}

export function* PricingSagasWatch() {
  yield takeLatest(type.SUBSCRIPTION_PLANS_REQUEST, requestSubscriptionPlans);
}
