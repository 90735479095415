
import * as types from '../../types/dashboardIndex';

const INITIAL_STATE = {
    index : '/admin/dashboard/registeredCompanies' 
}

export default function locale(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_ACTIVE_PAGE:
      return { ...state, ...action.payload};
    default:
      return state;
  }
};