import { axiosInstance } from "../config";

let handlerEnabled = true;

const getHeroContentAPI = async () => {
  return await axiosInstance.get("/hrcomsite/home/hero", { handlerEnabled });
};
const getInfoContentAPI = async () => {
  return await axiosInstance.get("/hrcomsite/home/info", { handlerEnabled });
};
const getProductsContentAPI = async () => {
  return await axiosInstance.get("/hrcomsite/home/products", {
    handlerEnabled,
  });
};
const getNewsContentAPI = async () => {
  return await axiosInstance.get("/hrcomsite/home/news", { handlerEnabled });
};
const getFeaturesContentAPI = async () => {
  return await axiosInstance.get("/hrcomsite/home/features", {
    handlerEnabled,
  });
};
const getReviewsContentAPI = async () => {
  return await axiosInstance.get("/hrcomsite/home/reviews", { handlerEnabled });
};
const getQuestionsContentAPI = async () => {
  let params = {
    num_of_records: 8,
  };
  return await axiosInstance.get("/hrcomsite/home/faqs", {
    params,
    handlerEnabled,
  });
};

const postSubscribe = async (data) => {
  return await axiosInstance.post("/hrcomsite/home/subscription", data, {
    handlerEnabled,
  });
};

const getAboutUs = async () => {
  return await axiosInstance.get("/hrcomsite/pages/AboutUs", {
    handlerEnabled,
  });
};

const getProductDetails = async (id) => {
  return await axiosInstance.get(`/hrcomsite/pages/Products/${id}`, {
    handlerEnabled,
  });
};

const getNewsDetails = async (id) => {
  return await axiosInstance.get(`/hrcomsite/pages/News/${id}`, {
    handlerEnabled,
  });
};

const postContactUs = async (data) => {
  return await axiosInstance.post(
    "/hrcomsite/pages/ContactUs/SendingEmail",
    data,
    { handlerEnabled }
  );
};

const getContactUs = async () => {
  return await axiosInstance.get("/hrcomsite/pages/ContactUs", {
    handlerEnabled,
  });
};

const registration = async (data) => {
  return await axiosInstance.post("/Company/SignUp", data, { handlerEnabled });
};

const sendEmailAgain = async (data) => {
  data = JSON.stringify(data);
  return await axiosInstance.post("/Company/ResendConfirmEmail", data, {
    handlerEnabled,
  });
};

const confirmEmail = async (data) => {
  return await axiosInstance.post("/Company/confirmEmail", data, {
    handlerEnabled,
  });
};
const confirmEmailWithoutPassword  = async (data) => {
  return await axiosInstance.post("/Company/confirmEmail_withoutPassword", data, {
    handlerEnabled,
  });
};

const getCandidateProfileSetup = async () => {
  return await axiosInstance.get(
    "/hrcomsite/JobSeeker/get_candidateProfileSetup",
    { headers: { domain: "hrcomadministration" }, handlerEnabled }
  );
};

const isCandidateRegistredBefore = async (data) => {
  return await axiosInstance.put(
    `/hrcomsite/JobSeeker/is_candidate_registedBefore`,
    data,
    {
      headers: { domain: "hrcomadministration" },
      handlerEnabled,
    }
  );
};

const addJobSeeker = async (data) => {
  return await axiosInstance.post(
    "/hrcomsite/JobSeeker/addEdit_candidateData",
    data,
    { headers: { domain: "hrcomadministration" }, handlerEnabled }
  );
};

const requestNewDemo = async (data) => {
  return await axiosInstance.post(
    "/hrcomsite/home/request_demo",
    data
  );
};
const addEditCompanyOffice365Integration = async (data) => {
  return await axiosInstance.put(
    `/admin/companyIntegration/addEdit_CompanyMicrosoftCalendarIntegrationData`,
    data,
    {
      handlerEnabled,
    }
  );
};
export default {
  getHeroContentAPI,
  getInfoContentAPI,
  getProductsContentAPI,
  getNewsContentAPI,
  getFeaturesContentAPI,
  getReviewsContentAPI,
  getQuestionsContentAPI,
  getAboutUs,
  getProductDetails,
  getNewsDetails,
  postSubscribe,
  postContactUs,
  getContactUs,
  registration,
  sendEmailAgain,
  confirmEmail,
  getCandidateProfileSetup,
  isCandidateRegistredBefore,
  addJobSeeker,
  requestNewDemo,
  addEditCompanyOffice365Integration,
  confirmEmailWithoutPassword
};
