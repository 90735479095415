import * as types from "../../types/website/auth";

const INITIAL_STATE = {
    primary_color: '#808080',
    secondary_color :'#808080',
    defaultPrimaryColor: '#86364e',
    defaultSecondaryColor: '#ea8685',
    TransparentColor: 'rgba(172, 69, 86, 0.06)'
}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.GET_COMPANY_PORTAL_SETUP_RECIEVE:
            return { ...state, ...action.payload };
        default: return state;
    }
}