export const COMPANY_LOGIN_REQUEST = 'COMPANY_LOGIN_REQUEST';
export const COMPANY_FORGET_PASSWORD_REQUEST  = 'COMPANY_FORGET_PASSWORD_REQUEST';
export const SOCIAL_LOGIN_REQUEST = 'SOCIAL_LOGIN_REQUEST';
export const SOCIAL_LOGIN_PREREQUESTION_MODAL_REQUEST = "SOCIAL_LOGIN_PREREQUESTION_MODAL_REQUEST";
export const SOCIAL_LOGIN_PREREQUESTION_FILLED_REQUEST = "SOCIAL_LOGIN_PREREQUESTION_FILLED_REQUEST";
export const SOCIAL_LOGIN_POPUP_OPENED_REQUEST = "SOCIAL_LOGIN_POPUP_OPENED_REQUEST";
export const SOCIAL_LOGIN_PHONE_NUMBER_REQUIRED = "SOCIAL_LOGIN_PHONE_NUMBER_REQUIRED";
export const REQUEST_COMPANY_SOCIAL_LOGIN = "REQUEST_COMPANY_SOCIAL_LOGIN";
export const REDIRECT_COMPANY_TO_VALIDATE_DOMAIN_REQUEST = "REDIRECT_COMPANY_TO_VALIDATE_DOMAIN_REQUEST"
export const REDIRECT_COMPANY_TO_LOGIN_REQUEST = "REDIRECT_COMPANY_TO_LOGIN_REQUEST";
export const GET_COMPANY_PORTAL_SETUP_REQUEST = 'GET_COMPANY_PORTAL_SETUP_REQUEST';
export const GET_COMPANY_PORTAL_SETUP_RECIEVE = 'GET_COMPANY_PORTAL_SETUP_RECIEVE';
export const REQUEST_REDIRECT_COMPANY_LOGIN_WITH_OTP = 'REQUEST_REDIRECT_COMPANY_LOGIN_WITH_OTP';
export const  SEND_TOW_FACTOR_CODE_REQUEST= "SEND_TOW_FACTOR_CODE_REQUEST";
export const  SEND_TOW_FACTOR_CODE_RECEIVE = "SEND_TOW_FACTOR_CODE_RECEIVE";
