import { axiosInstance } from "../config";

let handlerEnabled = true;

const residenciesLookupRequest = async () => {
  return await axiosInstance.get(`/lookup/residency/get`, {
    headers: { domain: "hrcomadministration" },
    handlerEnabled,
  });
};

const collegeLookupRequest = async () => {
  return await axiosInstance.get("/lookup/college/get", {
    headers: { domain: "hrcomadministration" },
    handlerEnabled,
  });
};
const degreeLookupRequest = async () => {
  return await axiosInstance.get("/lookup/degree/get", {
    headers: { domain: "hrcomadministration" },
    handlerEnabled,
  });
};
const jobTitleLookupRequest = async () => {
  return await axiosInstance.get("/admin/job_title/lookup", {
    headers: { domain: "hrcomadministration" },
    handlerEnabled,
  });
};

const languagesWithOutEnglish = async () => {
  return await axiosInstance.get(`/lookup/language/without_english`, {
    headers: { domain: "hrcomadministration" },
    handlerEnabled,
  });
};

const skillsLookupsRequest = async () => {
  return await axiosInstance.get(`/lookup/competencies/get`, {
    headers: { domain: "hrcomadministration" },
    handlerEnabled,
  });
};
const currencyLookup = async () => {
  return await axiosInstance.get(`/lookup/currency/get`, {
    headers: { domain: "hrcomadministration" },
    handlerEnabled,
  });
};

const companieslookup = async () => {
  return await axiosInstance.get(`/hrcomsite/company/lookup`, {
    headers: { domain: "hrcomadministration" },
    handlerEnabled,
  });
};

const getHearAboutUsList = async () => {
  return await axiosInstance.get(`/admin/lookups/hear_about_us`);
};

export default {
  collegeLookupRequest,
  degreeLookupRequest,
  jobTitleLookupRequest,
  residenciesLookupRequest,
  languagesWithOutEnglish,
  skillsLookupsRequest,
  currencyLookup,
  companieslookup,
  getHearAboutUsList
};
