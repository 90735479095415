import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/dashboard/candidateSetup";
import * as Actions from "../../actions/dashboard/candidateSetup";
import * as type from "../../types/dashboard/candidateSetup";
import { showSnackbar, dispatchError } from "../../../utils/Shared";
import History from "../../../routes/History";

function* candidatePortalSetup({ payload }) {
  try {
    const response = yield call(API.candidatePortalSetup, payload);
    yield put(Actions.candidatePortalSetupRecieve(response.data.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* saveCandidateProfileSetup({ payload }) {
  try {
    yield call(API.saveCandidatePortalSetup, payload);
    showSnackbar("savedSuccessfuly");
    History.goBack();
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* requestCandidates({ payload }) {
  try {
    const response = yield call(API.candidatesRequest, payload);
    yield put(Actions.receiveCandidates(response.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

export function* requestCandidateProfile({ payload }) {
  try {
    const response = yield call(API.requestCandidateProfile, payload);
    yield put(Actions.receiveCandidateProfile(response.data.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}
function* requestSendEmail({ payload }) {
  try {
    yield call(API.requestSendEmail, payload);
    showSnackbar("emailSentSuccessfully");
  } catch (error) {
    dispatchError(error.response.data);
  }
}

export default function* CandidateProfileSetupSagasWatch() {
  yield takeLatest(type.CANDIDATE_PORTAL_SETUP_REQUEST, candidatePortalSetup);
  yield takeLatest(type.SAVE_PROFILE_SETUP_REQUEST, saveCandidateProfileSetup);
  yield takeLatest(type.CANDIDATES_REQUEST, requestCandidates);
  yield takeLatest(type.CANDIDATE_PROFILE_REQUEST, requestCandidateProfile);
  yield takeLatest(type.SEND_EMAIL_REQUEST, requestSendEmail);
}
