import * as types from "../../types/dashboard/subscriptions";

const INITIAL_STATE = {
  plans: [],
  paging: {},
  usedPlans: [],
  plan: {
    payment_type: [],
  },
  companies: [],
  paymentType: [],
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DASHBOARD_SUBSCRIPTION_PLANS_RECIEVE:
      return {
        ...state,
        paging: action.payload.paging,
        plans: action.payload.data,
      };
    case types.GET_DASHBOARD_SUBSCRIPTION_PLAN_RECIEVE:
      return {
        ...state,
        plan: { ...state.plan, ...action.payload.data },
      };
    case types.GET_SUBSCRIPTION_COMPANIES_RECIEVE:
      return {
        ...state,
        companies: action.payload.data.filter(item => item.name_en && item.name_ar),
      };
    case types.GET_SUBSCRIPTION_PAYMENT_TYPE_RECIEVE:
      return {
        ...state,
        paymentType: action.payload.data,
      };
      case types.GET_DASHBOARD_SUBSCRIPTION_USED_PLAN_RECIEVE:
        return {
          ...state,
          usedPlans: action.payload.data,
        };
    default:
      return state;
  }
};
