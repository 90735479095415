import * as types from "../../types/dashboard/promoCodes";

const INITIAL_STATE = {
  promoCodeList: {},
  sendPromoCode: null,
  singlePromoCode: {},
  infoPromoCode: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_PROMO_CODES_LIST_RECEIVE:
      return { ...state, promoCodeList: { ...action.payload } };
    case types.GET_SINGLE_PROMO_CODE_RECEIVE:
      return { ...state, singlePromoCode: { ...action.payload } };
    case types.ADD_PROMO_CODE_RECEIVE:
      return { ...state, singlePromoCode: { ...action.payload } };
    case types.EDIT_SINGLE_PROMO_CODE_RECEIVE:
      return { ...state, singlePromoCode: { ...action.payload } };
    case types.SEND_PROMO_CODE_RECEIVE:
      return { ...state, sendPromoCode: { ...action.payload } };
    case types.INFO_PROMO_CODE_RECEIVE:
      return { ...state, infoPromoCode: { ...action.payload } };
    case types.INFO_PROMO_CODE_SEND_BY_RECEIVE:
      return { ...state, infoPromoCodeSendBy: { ...action.payload } };
    default:
      return state;
  }
};
