export const CANDIDATE_PORTAL_SETUP_REQUEST = 'CANDIDATE_PORTAL_SETUP_REQUEST'
export const CANDIDATE_PORTAL_SETUP_RECIEVE = 'CANDIDATE_PORTAL_SETUP_RECIEVE'

export const SAVE_PROFILE_SETUP_REQUEST = 'SAVE_PROFILE_SETUP_REQUEST'

export const CANDIDATES_REQUEST = 'CANDIDATES_REQUEST'
export const CANDIDATES_RECEIVE = 'CANDIDATES_RECEIVE'

export const CANDIDATE_PROFILE_REQUEST = 'CANDIDATE_PROFILE_REQUEST';
export const CANDIDATE_PROFILE_RECEIVE = 'CANDIDATE_PROFILE_RECEIVE';
export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST'
