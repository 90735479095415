import * as types from '../../types';

export const requestRegistration = (data) => {
  return {
    type: types.REGISTRATION_REQUEST,
    payload: data
  }
};


export const receiveRegistration = (data) => {
  return {
    type: types.REGISTRATION_RECEIVE,
    payload: data
  }
};

export const requestSendAgainEmail = (data) => {
  return {
    type: types.SEND_AGAIN_EMAIL_REQUEST,
    payload: data
  }
};


export const receiveSendAgainEmail = (data) => {
  return {
    type: types.SEND_AGAIN_EMAIL_RECEIVE,
    payload: data
  }
};


export const requestConfirmEmails = (data) => {
  return {
    type: types.CONFIRM_EMAIL_REQUEST,
    payload: data
  }
};
export const requestConfirmEmailsWithoutPassword = (data) => {
  return {
    type: types.CONFIRM_EMAIL_WITHOUT_PASSWORD_REQUEST,
    payload: data
  }
};

export const receiveConfirmEmails = (data) => {
  return {
    type: types.CONFIRM_EMAIL_RECEIVE,
    payload: data
  }
};


export const requestNewDemo = (payload) => {
  return {
    type: types.REQUEST_NEW_DEMO,
    payload
  }
};