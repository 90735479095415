import { axiosInstance } from "../config";

let handlerEnabled = true;

const requestDashboardData = async () => {
  return await axiosInstance.get(`/hrcomsite/company/get_cards_data`, {
    handlerEnabled,
  });
};

const requestPlansData = async () => {
  return await axiosInstance.get(`/hrcomsite/subscriptions/plans/companies/count`, {
    handlerEnabled,
  });
};

const requestPackagesData = async () => {
  return await axiosInstance.get(`/`, {
    handlerEnabled,
  });
};

export default {
    requestDashboardData,
    requestPlansData,
    requestPackagesData
};
