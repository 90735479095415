export const REGISTERED_COMPANIES_REQUEST = 'REGISTERED_COMPANIES_REQUEST'
export const REGISTERED_COMPANIES_RECEIVE = 'REGISTERED_COMPANIES_RECEIVE'

export const ACTIVATE_COMPANIES_REQUEST = 'ACTIVATE_COMPANIES_REQUEST'
export const ACTIVATE_COMPANIES_RECEIVE = 'ACTIVATE_COMPANIES_RECEIVE'

export const DEACTIVATE_COMPANIES_REQUEST = 'DEACTIVATE_COMPANIES_REQUEST'
export const DEACTIVATE_COMPANIES_RECEIVE = 'DEACTIVATE_COMPANIES_RECEIVE'

export const TABS_COUNT_REQUEST = 'TABS_COUNT_REQUEST'
export const TABS_COUNT_RECEIVE = 'TABS_COUNT_RECEIVE'

export const AGENTS_REQUEST = 'AGENTS_REQUEST'
export const AGENTS_RECEIVE = 'AGENTS_RECEIVE'

export const JOB_OPENNINGS_REQUEST = 'JOB_OPENNINGS_REQUEST'
export const JOB_OPENNINGS_RECEIVE = 'JOB_OPENNINGS_RECEIVE'

export const ALL_COMPANIES_MINUTES_REQUEST = 'ALL_COMPANIES_MINUTES_REQUEST'
export const ALL_COMPANIES_MINUTES_RECEIVE = 'ALL_COMPANIES_MINUTES_RECEIVE'

export const COMPANY_SUBSCRIPTION_DETAILS_REQUEST = 'COMPANY_SUBSCRIPTION_DETAILS_REQUEST'
export const COMPANY_SUBSCRIPTION_DETAILS_RECEIVE = 'COMPANY_SUBSCRIPTION_DETAILS_RECEIVE'

export const COMPANY_SUBSCRIPTION_PLANS_LOOKUP_RECIEVE = "COMPANY_SUBSCRIPTION_PLANS_LOOKUP_RECIEVE";
export const COMPANY_SUBSCRIPTION_PLANS_LOOKUP_REQUEST = "COMPANY_SUBSCRIPTION_PLANS_LOOKUP_REQUEST";
export const APPEAR_ON_JOB_SEEKER = "APPEAR_ON_JOB_SEEKER"

export const VIEW_INVOICE_RECIEVE = 'VIEW_INVOICE_RECIEVE';
export const VIEW_INVOICE_REQUEST = 'VIEW_INVOICE_REQUEST';

export const COMPANY_SUBSCRIPTION_HISTORY_LIST_RECIEVE = "COMPANY_SUBSCRIPTION_HISTORY_LIST_RECIEVE";
export const COMPANY_SUBSCRIPTION_HISTORY_LIST_REQUEST = "COMPANY_SUBSCRIPTION_HISTORY_LIST_REQUEST";

export const AGENT_RESEND_CONFIRMATION_REQUEST = 'AGENT_RESEND_CONFIRMATION_REQUEST';
export const AGENT_RESEND_CONFIRMATION_RECEIVE = 'AGENT_RESEND_CONFIRMATION_RECEIVE';

export const COMPANY_DETAILS_REQUEST = 'COMPANY_DETAILS_REQUEST';
export const COMPANY_DETAILS_RECIEVE = 'COMPANY_DETAILS_RECIEVE';

export const RESEND_CONFIRMATION_CREATOR_REQUEST = 'RESEND_CONFIRMATION_CREATOR_REQUEST';
