import React from "react";
import SideMenu from "../../components/SideMenu/SideMenu";
import Navbar from "../../components/Navbar/Navbar";
import "./MainLayout.scss";
import { connect } from "react-redux";
import { setClickedPageIndex } from "../../../store/actions/dashboard/clickedPage";
import History from "routes/History";
class MainLayout extends React.Component {
  constructor() {
    super();
    this.state = {
      pathname: "/admin/dashboard/registeredCompanies",
    };
  }

  componentDidUpdate() {
    const { setClickedPageIndex } = this.props;
    if (History.location.pathname !== this.state.pathname) {
      this.setState({ pathname: History.location.pathname });
      setClickedPageIndex({ index: History.location.pathname });
    }
  }

  render() {
    const { sideMenu } = this.props;
    return (
      <React.Fragment>
        <SideMenu />
        <Navbar />
        <div
          className="main"
          id="main"
          style={{
            marginLeft: sideMenu.mainMrginLeft,
            marginRight: sideMenu.mainMarginRight,
          }}
        >
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.locale.lang,
    sideMenu: state.dashboard.sideMenu,
    activePageIndex: state.dashboard.activePageIndex.index,
  };
};

export default connect(mapStateToProps, { setClickedPageIndex })(MainLayout);
