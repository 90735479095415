import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/auth";
import * as TYPES from "../../types/dashboard/auth";
import History from "./../../../routes/History";
import { dispatchError } from "../../../utils/Shared";
import Auth from './../../../utils/Auth';

function* adminLogin({ payload }) {
  try {
    const response = yield call(API.adminLogin, payload);
    localStorage.setItem("token", response.data.data.token);
    localStorage.setItem("is_wizard", response.data.data.is_wizard);
    localStorage.setItem("first_name", response.data.data.first_name );
    localStorage.setItem("last_name", response.data.data.last_name);
    History.push("/admin/dashboard/home");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

function* forgotPassword({ payload }) {
  try {
    const response = yield call(API.adminForgotPassword, payload);
    History.push("admin/dashboard/login");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

function* resetPassword({ payload }) {
  try {
    const response = yield call(API.adminResetPassword, payload);
    History.push("/admin/dashboard/login");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

function* changePassword({ payload }) {
  try {
    const response = yield call(API.adminChangePassword, payload);
    Auth.logout();
    History.push("/admin/dashboard/login");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export default function* authSagas() {
  yield takeLatest(TYPES.ADMIN_LOGIN_REQUEST, adminLogin);
  yield takeLatest(TYPES.FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(TYPES.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(TYPES.CHANGE_PASSWORD_REQUEST, changePassword);
}
