import * as types from "../../types";

export const requestAboutUsContent = () => {
    return {
      type: types.FETCH_ABOUTUS_REQUEST
    }
  };
  
  export const receiveAboutUsContent = (data) => {
    return{
      type: types.FETCH_ABOUTUS_RECEIVE,
      payload: data
    }
  };