import { axiosInstance } from "../config";
let handlerEnabled = true;
const addPromoCode = async (data) => {
  return await axiosInstance.post(`/hrcomsite/promo_codes`, data, {
    handlerEnabled,
  });
};

const listPromoCodes = async (page_number) => {
  return await axiosInstance.get(`/hrcomsite/promo_codes`, {
    params: {
      page_size: 10,
      page_number,
    },
    handlerEnabled,
  });
};

const getPromocodeById = async (id) => {
  return await axiosInstance.get(`/hrcomsite/promo_codes/${id}`, {
    handlerEnabled,
  });
};

const editPromoCode = async (data) => {
  return await axiosInstance.put(`/hrcomsite/promo_codes/${data.id}`, data, {
    handlerEnabled,
  });
};

const deletePromoCode = async (id) => {
  return await axiosInstance.delete(`/hrcomsite/promo_codes/${id}`, {
    handlerEnabled,
  });
};

const sendPromoCode = async ({ id, data }) => {
  return await axiosInstance.post(`/hrcomsite/promo_codes/${id}/send`, data, {
    handlerEnabled,
  });
};

const infoPromoCode = async ({ id, page_number }) => {
  return await axiosInstance.get(`/hrcomsite/promo_codes/${id}/info`, {
    params: {
      page_size: 10,
      page_number,
    },
    handlerEnabled,
  });
};
const infoPromoCodeSendBy = async ({ id, page_number }) => {
  return await axiosInstance.get(`/hrcomsite/promo_codes/${id}/UsedBy`, {
    params: {
      page_size: 10,
      page_number,
    },
    handlerEnabled,
  });
};

export default {
  addPromoCode,
  listPromoCodes,
  getPromocodeById,
  editPromoCode,
  deletePromoCode,
  sendPromoCode,
  infoPromoCode,
  infoPromoCodeSendBy,
};
