import React from "react";
import "./Avatar.scss";

const Avatar = ({ name, image , color}) => {
  const splitFirstTwoLetters = (name) => {
    return name.substring(0, 2);
  };

  const showNameAvatar = (name) => {
    return (
      <div className="d-flex align-items-center justify-content-start">
        {image ? (
          <div
            className="avatarImage d-flex justify-content-center align-items-center"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ) : (
          <div className="avatarName bold_font d-flex justify-content-center align-items-center">
            {splitFirstTwoLetters(name)}
          </div>
        )}
        <p className={color ? 'mb-0 mx-2 fullName text-white' : 'mb-0 mx-2 fullName text-dark'}>{name}</p>
      </div>
    );
  };
  return (
    <React.Fragment>{name && name.length > 0 && showNameAvatar(name)}</React.Fragment>
  );
};

export default Avatar;
