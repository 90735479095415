import * as types from "../../types/dashboard/candidateSetup";

export const candidatePortalSetupRequest = () => ({
  type: types.CANDIDATE_PORTAL_SETUP_REQUEST,
});

export const candidatePortalSetupRecieve = (data) => ({
  type: types.CANDIDATE_PORTAL_SETUP_RECIEVE,
  payload: data,
});

export const saveProfileSetupRequest = (payload) => ({
  type: types.SAVE_PROFILE_SETUP_REQUEST,
  payload,
});

export const requestCandidates = (payload) => ({
  type: types.CANDIDATES_REQUEST,
  payload,
});
export const receiveCandidates = (payload) => ({
  type: types.CANDIDATES_RECEIVE,
  payload,
});

export const requestCandidateProfile = (payload) => {
  return {
    type: types.CANDIDATE_PROFILE_REQUEST,
    payload,
  };
};

export const receiveCandidateProfile = (payload) => {
  return {
    type: types.CANDIDATE_PROFILE_RECEIVE,
    payload,
  };
};
export const requestSendEmail = (payload) => ({
  type: types.SEND_EMAIL_REQUEST,
  payload,
});
