import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/dashboard/promoCodes";
import * as Actions from "../../actions/dashboard/promoCodes";
import * as type from "../../types/dashboard/promoCodes";
import {
  showSnackbar,
  dispatchError,
  pushToUrlNewParam,
} from "../../../utils/Shared";
import History from "../../../routes/History";

function* addPromoCode({ payload }) {
  try {
    const response = yield call(API.addPromoCode, payload.data);
    yield put(Actions.addPromoCodeReceive(response.data.data));
    showSnackbar("done");
    payload.isSaveSendType && History.push(`./promoCode/${response.data.data.id}`)
    !payload.isSaveSendType && History.goBack();
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* getPromoCodeList({ payload }) {
  try {
    const response = yield call(API.listPromoCodes, payload);
    yield put(Actions.getPromocodesListReceive(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* getSinglePromoCode({ payload }) {
  try {
    const response = yield call(API.getPromocodeById, payload);
    yield put(Actions.getSinglePromoCodeReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* editSinglePromoCode({ payload }) {
  try {
    yield call(API.editPromoCode, payload.data);
    yield put(Actions.editSinglePromoCodeReceive(payload.data));
    showSnackbar("done");
    if(!payload.isSaveSendType){
      History.push(`/admin/dashboard/promoCodes?page=${JSON.parse(localStorage.getItem("promoCodePage"))}`)
    }
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* deletePromoCode({ payload: { id, list, activePage } }) {
  try {
    yield call(API.deletePromoCode, id);
    yield put(
      Actions.getPromocodesListRequest(
        list.length === 1 && activePage > 1 ? activePage - 1 : activePage
      )
    );
    pushToUrlNewParam(
      list.length === 1 && activePage > 1 ? activePage - 1 : activePage
    );
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* sendPromoCode({ payload }) {
  try {
    yield call(API.sendPromoCode, payload);
    yield put(Actions.sendPromoCodeReceive({}));
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* infoPromoCode({ payload }) {
  try {
    const response = yield call(API.infoPromoCode, payload);
    yield put(Actions.infoPromoCodeReceive(response.data));
  } catch (error) {
    console.log(error);
  }
}
function* infoPromoCodeSendBy({ payload }) {
  try {
    const response = yield call(API.infoPromoCodeSendBy, payload);
    yield put(Actions.infoPromoCodeSendByReceive(response.data));
  } catch (error) {
    console.log(error);
  }
}

export default function* promoCodesSagasWatch() {
  yield takeLatest(type.ADD_PROMO_CODE_REQUEST, addPromoCode);
  yield takeLatest(type.GET_PROMO_CODES_LIST_REQUEST, getPromoCodeList);
  yield takeLatest(type.GET_SINGLE_PROMO_CODE_REQUEST, getSinglePromoCode);
  yield takeLatest(type.EDIT_SINGLE_PROMO_CODE_REQUEST, editSinglePromoCode);
  yield takeLatest(type.DELETE_PROMO_CODE_REQUEST, deletePromoCode);
  yield takeLatest(type.SEND_PROMO_CODE_REQUEST, sendPromoCode);
  yield takeLatest(type.INFO_PROMO_CODE_REQUEST, infoPromoCode);
  yield takeLatest(type.INFO_PROMO_CODE_SEND_BY_REQUEST, infoPromoCodeSendBy);
}
