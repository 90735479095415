export const DASHBOARD_SUBSCRIPTION_PLANS_REQUEST = "DASHBOARD_SUBSCRIPTION_PLANS_REQUEST";
export const DASHBOARD_SUBSCRIPTION_PLANS_RECIEVE = "DASHBOARD_SUBSCRIPTION_PLANS_RECIEVE";
export const GET_DASHBOARD_SUBSCRIPTION_PLAN_REQUEST = "GET_DASHBOARD_SUBSCRIPTION_PLAN_REQUEST";
export const GET_DASHBOARD_SUBSCRIPTION_PLAN_RECIEVE = "GET_DASHBOARD_SUBSCRIPTION_PLAN_RECIEVE";
export const GET_SUBSCRIPTION_PATMENT_TYPE_REQUEST = "GET_SUBSCRIPTION_PATMENT_TYPE_REQUEST";
export const GET_SUBSCRIPTION_COMPANIES_REQUEST = "GET_SUBSCRIPTION_COMPANIES_REQUEST"
export const GET_SUBSCRIPTION_COMPANIES_RECIEVE = "GET_SUBSCRIPTION_COMPANIES_RECIEVE";
export const GET_SUBSCRIPTION_PAYMENT_TYPE_RECIEVE = "GET_SUBSCRIPTION_PAYMENT_TYPE_RECIEVE";
export const SET_SUBSCRIPTION_PLAN_REQUEST = "SET_SUBSCRIPTION_PLAN_REQUEST";
export const DELETE_SUBSCRIPTION_PLAN_REQUEST = "DELETE_SUBSCRIPTION_PLAN_REQUEST";
export const GET_DASHBOARD_SUBSCRIPTION_USED_PLAN_REQUEST = "GET_DASHBOARD_SUBSCRIPTION_USED_PLAN_REQUEST";
export const GET_DASHBOARD_SUBSCRIPTION_USED_PLAN_RECIEVE = "GET_DASHBOARD_SUBSCRIPTION_USED_PLAN_RECIEVE";